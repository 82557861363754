import React from "react";
import "../assets/scss/boards.scss";
import { Link } from "react-router-dom";
import { t } from "i18next";
import { Col, Row } from "react-bootstrap";

const Board = ({ id, fullname, order, photo, position }) => {
  return (
    <>
      <Link to={`/about/boards/detail/${id}`} className="board">
        <Row className="mx-0">
          <Col xs="3" md={order === 1 ? 3 : order === 2 || order === 3 ? 6 : 12}>
            <div className="board-photo">
              <img src={photo?.photoName} alt="...." />
            </div>
          </Col>
          <Col xs="9" md={order === 1 ? 9 : order === 2 || order === 3 ? 6 : 12}>
            <div className="board-detail">
              <div className={`board-name font-weight-bold ${order === 1 ? 'w-100' : ''}`}>
                {fullname}
                <div className="board-position">{position}</div>
              </div>
              {order <= 3 && (
                <div className="board-more">
                  <span className="mybtn">
                    {t("more")}
                    <svg className="ml-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path d="M12.0254 4.94167L17.0837 10L12.0254 15.0583" stroke="#202939" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M2.91602 10H16.941" stroke="#202939" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </span>
                </div>
              )}
            </div>
          </Col>
        </Row>


      </Link>
    </>
  );
};

export default Board;
