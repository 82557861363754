import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import AnimatedPage from "../config/transition";
import "swiper/css";
import "swiper/css/navigation";
import "../assets/scss/home.scss";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import Frame from "../components/Frame";
import BodiesService from "../services/bodiesService";
import { Link, useParams } from "react-router-dom";
import { t } from "i18next";

const CommissionDetailList = () => {

  var [data, setData] = useState([]);
  var [currentData, setCurrentData] = useState([]);
  const params = useParams();

  const loadBodiesData = async () => {
    try {
      const response = await BodiesService.getData();
      setData(response.result.filter((x) => x.structure?.id === params.id));

    } catch (error) {
      console.error("API error About", error);
    }
  };
  // Get the current data of the structure
  const loadStructuresData = async () => {
    try {
      const response = await BodiesService.getStructures();
      setCurrentData(response.result.find(x => x.id === params.id))

    } catch (error) {
      console.error("API error About", error);
    }
  };

  useEffect(() => {
    loadStructuresData();
    loadBodiesData();
  }, []);

  const frameData = {
    name: currentData?.name,
    photo: require('../assets/images/frames/com-detail.png'),
    page: 'pages.about.commission',
    preUrl: "/about/commission",
    url: '/about/commission',
    isCover: true,
  }
  return (
    <>
      <section className="commission detail">
        <AnimatedPage>
          <AnimatedPage>
            <div>
              <Container>

                <Frame {...frameData} />

                {/* <Link className="mybtn my-3 d-inline-block" to={`/about/commission/${currentData.id}`}>
                  <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M16.6668 10H3.3335M3.3335 10L8.3335 15M3.3335 10L8.3335 5" stroke="#697586" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  {t("back")}</Link> */}
                <Row className="commission-list pb-5">
                  {data.map((item, index) => (
                    <Col md="3"
                      key={index}
                    >
                      <div className="commission d-flex align-items-center flex-wrap">
                        <div className="commission-name w-100">
                          {item?.fullname}
                        </div>
                        <div className="commission-position w-100">
                          {item?.position}
                        </div>

                      </div>

                    </Col>
                  ))}
                </Row>
              </Container>

            </div>
          </AnimatedPage>
        </AnimatedPage>
      </section>
    </>
  );
};

export default CommissionDetailList;
