import React from "react";
import dayjs from "dayjs"
import { Link } from "react-router-dom";


const News = ({ id, description, header, photo, createTime, photos }) => {

  const lang = localStorage.getItem("language") || "az";
  require("dayjs/locale/az")
  dayjs.locale(lang)
  return (
    <>
      <Link to={`/news/detail/${id}`} className="news d-block">
        <div className="news-photo">
          <img src={photo} alt="..." />
        </div>
        <div className="news-detail pt-0">
          <div className="news-title">{header}</div>
          {/* <div className="news-content">{parse(description ?? '')}</div> */}
          <div className="news-date">
            {dayjs(createTime).format("DD MMMM YYYY").toUpperCase()}
          </div>
        </div>
      </Link>
    </>
  );
};

export default News;
