// src/services/newsService.js

import { get } from "./api-service";

const ActivityService = {
  getActivities: async () => {
    try {
      const response = await get("activities").catch((error) => console.error(error));
      if (!response.statusCode === 200) {
        throw new Error("API error");
      }
      const data = await response;
      return data;
    } catch (error) {
      throw error;
    }
  },
  getActivityDetail: async (id) => {
    try {
      const response = await get(`activities/${id}`).catch((error) =>
        console.error(error)
      );
      if (!response.statusCode === 200) {
        throw new Error("API error");
      }
      const data = await response;
      return data;
    } catch (error) {
      throw error;
    }
  },
};

export default ActivityService;
