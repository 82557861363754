import React, { useEffect, useState } from "react";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import Loader from "./components/Loader";
import { ToastContainer } from "react-toastify";

const RootComponent = () => {
  const [isLoading, setIsLoading] = useState(true);
  const dependency = 0;

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, [dependency]);


  return (
    <Router>
   {/* <Loader /> */}
      {isLoading ? <Loader /> : ""}
      <App />
      <ToastContainer />

    </Router>
  );
};

const container = document.getElementById("root");
const root = ReactDOMClient.createRoot(container);
root.render(<RootComponent />);
