import React from 'react'
import '../assets/scss/activity.scss'
import AnimatedPage from '../config/transition'
import '../assets/scss/activity.scss'
import ErrorComponent from "../components/Error"
const Error = () =>   {
    return (
      <>
      <AnimatedPage>
        <section className='error py-5'>
          <ErrorComponent/>
        </section>
      </AnimatedPage>
      </>
    )
}

export default Error 