import React, { useEffect, useState } from "react";
import AnimatedPage from "../config/transition";
import { Col, Container, Row } from "react-bootstrap";
import "../assets/scss/partners.scss";
import Frame from "../components/Frame";
import Partner from "../components/Partner";
import PartnerService from "../services/partnerService";
import Modal from 'react-bootstrap/Modal';
import parse from "html-react-parser";
const Partners = () => {
  var [data, setData] = useState([]);
  var [currentData, setCurrentData] = useState([]);
  const loadPartnersData = async () => {
    try {
      const response = await PartnerService.getPartners();
      setData(response.result);
    } catch (error) {
      console.error("API error About", error);
    }
  };

  useEffect(() => {
    loadPartnersData();
  }, []);


  const frameData = {
    name: 'pages.about.partners',
    photo: require('../assets/images/frames/partners.png'),
    page: 'pages.about',
    preUrl: "/about",
    url: '/about/partners'
  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const showPartner = (item) => {
    setShow(true);
    setCurrentData(item)
  };
  return (
    <>
      <section className="partners">
        <AnimatedPage>
          <div className="py-5">
            <Container>
              <Frame {...frameData} />
              <Row>
                {data?.map((item, index) => (
                  <Col md="4"  onClick={()=>showPartner(item)}  key={index} className="mb-3">
                    <Partner {...item} />
                  </Col>
                ))}
              </Row>
            </Container>
          </div>


          <Modal show={show} onHide={handleClose} animation={false}>
        
            <Modal.Body>
              <div className="partner-detail">
                <div className="partner">
                  <div className="partner-photo">
                    <img src={currentData && currentData.photo ?currentData.photo.photoName:''} alt="" />
                  </div>
                  <div className="partner-name">{currentData.header}</div>
                  <div className="partner-desc">{parse(currentData.description??'')}</div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </AnimatedPage>
      </section>
    </>
  );
};

export default Partners;
