import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import "../assets/scss/gallery.scss";
import AnimatedPage from "../config/transition";
import GalleryService from "../services/galleryService";
import parse from "html-react-parser";
import Frame from "../components/Frame";
import Fancybox from "../pages/Fancybox";
import { Link } from "react-router-dom";

const Gallery = () => {
  const [data, setData] = useState([]);
  const [photos, setPhoto] = useState([]);


  const LoadData = async () => {
    try {
      const response = await GalleryService.getGalleries();
      setData(response.result);
    } catch (error) {
      console.error("API error Gallery", error);
    }
  };

  const getData = (item) => {
    let photos = item.photos
    setPhoto(photos)
  }
  useEffect(() => {
    LoadData();
  }, []);



  const frameData = {
    name: 'pages.gallery',
    photo: require('../assets/images/frames/gallery.png'),
    page: 'pages.gallery',
    isCover: true,
    url: '/gallery'
  }

  return (
    <>
      <AnimatedPage>
        <Container>
          <Frame {...frameData} />

          <section className="media-page  py-md-5">
            {data?.map((item, index) => (
              <div key={index} className="medias">
                <div className="media-box">
                  <div className="media-photo">
                    <Fancybox
                      options={{
                        Carousel: {
                          autoFocus: true,
                          infinite: true,
                        },
                      }}
                    >

                      <a data-fancybox="gallery" href={item.photo}>
                        <img src={item.photo ? item.photo : item.photos[0].link} alt="..." />
                      </a>
                      {item.photos.map((photo, index) => (
                        <a className="col-md-4 d-none" data-fancybox="gallery" href={photo.link}>
                          <img src={photo.link} alt="..." />
                        </a>
                      ))}

                    </Fancybox>
                    {/* <img src={item.photo ? item.photo : item.photos[0].link} alt="..." /> */}
                  </div>
                  <div className="media-title">{parse(item.description ?? '')}</div>
                </div>
              </div>
            ))}


          </section>

        </Container>
      </AnimatedPage>
    </>
  );
};

export default Gallery;
