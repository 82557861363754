import React, { useEffect, useState } from "react";
import AnimatedPage from "../config/transition";
import AboutService from "../services/aboutService";
import { Container } from "react-bootstrap";
import parse from "html-react-parser";
import Frame from "../components/Frame";

const Constitution = () => {
  const [about, setAbout] = useState([]);

  const loadAboutData = async () => {
    try {
      const data = await AboutService.getAboutData("constitution");
      setAbout(data.result);
    } catch (error) {
      console.error("API error About", error);
    }
  };

  useEffect(() => {
    loadAboutData();
  }, []);
  const frameData = {
    name:'pages.about.constitution',
    photo: require('../assets/images/frames/const.png'),
    page:'pages.about',
    preUrl:"/about",
    url:'/about/constitution'
  }

  return (
    <>
      <section className="mb-5">
        <AnimatedPage>
          <section className="p-md-5">
            <Container>
              <Frame {...frameData}/>
              <div className="d-flex align-items-center ">
                <div className="w-100">
                  <div className="d-flex w-100 justify-content-between">
                    {/* {about.document && (
                      <div>
                        <a  download target="_blank" rel="noreferrer" href={about.document}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="41" viewBox="0 0 34 41" fill="none">
                          <path d="M25.8199 8.80579H26.2494C26.1161 8.6597 25.9667 8.5003 25.8199 8.31541C24.2855 6.58445 21.6073 3.95068 19.8718 2.43422C19.6834 2.27482 19.5085 2.12805 19.3479 1.99598V7.54271C19.3479 8.23337 19.9259 8.80573 20.6261 8.80573L25.8199 8.80579ZM20.6261 10.6298C18.9161 10.6298 17.5047 9.23191 17.5047 7.54276V2.43427H3.41768C2.89384 2.43427 2.46291 2.86034 2.46291 3.39183V32.5661C2.46291 33.098 2.89382 33.5223 3.41768 33.5223H17.2502L15.6217 31.9142H34L24.8109 41L19.7121 35.9577L3.41762 35.9579C1.54851 35.9579 0 34.4289 0 32.5661V3.39182C0 1.52974 1.54851 0 3.41762 0H19.7252C20.102 0.25197 20.5859 0.652093 21.2175 1.21093H21.2326C21.6359 1.55661 22.0675 1.94205 22.5779 2.43379C23.049 2.88674 23.6011 3.39135 24.2063 4.0044C24.8122 4.61539 25.3495 5.14689 25.8212 5.62805C26.3051 6.13244 26.6954 6.57138 27.045 6.94394C27.6243 7.59673 27.9998 8.0756 28.2559 8.44681V19.2637H25.8212L25.821 10.6295L20.6261 10.6298Z" fill="#2B3D5C" />
                          <path d="M30.2592 27.2179V30.6767H19.2807V27.2179H30.2592Z" fill="#2B3D5C" />
                          <path d="M30.1522 23.2941V26.0488H19.173V23.2941H30.1522Z" fill="#2B3D5C" />
                          <path d="M30.1522 20.3671V22.1501H19.173V20.3671H30.1522Z" fill="#2B3D5C" />
                          <path d="M25.8199 8.31541C25.9669 8.50099 26.1161 8.65971 26.2494 8.80579H25.8199V8.31541Z" fill="#2B3D5C" />
                          <path d="M19.3479 2.43421V1.99597C19.5091 2.12873 19.684 2.2755 19.8717 2.43421H19.3479Z" fill="#2B3D5C" />
                        </svg>
                        </a>
                      </div>
                    )} */}


                  </div>
                  <div className="fullname font-weight-bold">
                    {about?.fullname}
                  </div>
                  <div className="position">{about.position}</div>
                  <div className="text py-3 px-0">{parse(about?.text ?? '')}</div>
                </div>
              </div>
            </Container>
          </section>
        </AnimatedPage>
      </section>
    </>
  );
};

export default Constitution;
