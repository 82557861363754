import React, { useEffect, useState } from "react";
import NewsItem from "../components/News";
import { Container, Row } from "react-bootstrap";
import AnimatedPage from "../config/transition";
import newsService from "../services/newsService";
import Frame from "../components/Frame";
import { useTranslation } from "react-i18next";

const News = () => {
  const [news, setNews] = useState([]);
  const [allnews, setAllNews] = useState([]);
  var [check, setChecker] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    const loadNews = async () => {
      try {
        const data = await newsService.getNews();
        setNews(data.result);
        setAllNews(data.result);
      } catch (error) {
        console.error("API error", error);
      }
    };
    loadNews();
  }, []);

  const filteredNews = (type) => {
    setChecker(!type);
    if(type){
      setNews(allnews.filter(x => x.isNews));
    }
    else{
      setNews(allnews.filter(x => x.isAnnouncement));
    }
    console.log(news)
  }


  const frameData = {
    name:'pages.news',
    photo: require('../assets/images/frames/news.png'),
    page:'pages.news',
    isCover:true,
    url:'/news'
  }

  return (
    <>
      <AnimatedPage>
        <Container>
          <Frame {...frameData}/>
          <ul className="d-flex buttons">
                <li onClick={() => filteredNews(true)} className={`${!check ? "active" : ""} mr-3 cursor-pointer mybtn`}>
                  {t("pages.news")}
                </li>
                <li onClick={() => filteredNews(false)} className={`${check ? "active" : ""} mr-3 cursor-pointer mybtn`}>
                  {t("pages.news.announces")}
                </li>
              </ul>
          <section className="newsList py-3">
            <Row>
              {news?.map((item) => (
                <div key={item.id} className="col-md-4 mb-4">
                  <NewsItem {...item} />
                </div>
              ))}
            </Row>
          </section>
        </Container>
      </AnimatedPage>
    </>
  );
};

export default News;
