// src/services/newsService.js

import { get,post } from "../services/api-service";

const NewsService = {
  getNews: async () => {
    try {
      const response = await get("news").catch((error) => console.error(error));
      if (!response.statusCode === 200) {
        throw new Error("API error");
      }
      const data = await response;
      return data;
    } catch (error) {
      throw error;
    }
  },
  getNewsByType: async (payload) =>  {
    try {
      const response = await post("news/types/true",payload).catch((error) => console.error(error));
      if (!response.statusCode === 200) {
        throw new Error("API error");
      }
      const data = await response;
      return data;
    } catch (error) {
      throw error;
    }
  },
  getNewsDetail: async (id) => {
    try {
      const response = await get(`news/${id}`).catch((error) =>
        console.error(error)
      );
      if (!response.statusCode === 200) {
        throw new Error("API error");
      }
      const data = await response;
      return data;
    } catch (error) {
      throw error;
    }
  },
};

export default NewsService;
