import React, { useEffect, useState } from "react";
import AnimatedPage from "../config/transition";
import { Container } from "react-bootstrap";
import PublicationService from "../services/materialsService";
import { useParams } from "react-router-dom";
import Frame from "../components/Frame";

import '../assets/scss/materials.scss'
import { useTranslation } from "react-i18next";

const Useful = () => {
  const [data, setData] = useState([]);
  const params = useParams();
  const { t } = useTranslation();
  const loadData = async () => {
    const d = {
      isInternationalDocuments: paramsChecker(params, "internaldoc"),
      isLegislation: paramsChecker(params, "legislation"),
      isProse: paramsChecker(params, "publications"),
      isUsefulLinks: paramsChecker(params, "links"),
    }
    try {
      const data = await PublicationService.getMaterialsByType(d);
      setData(data.result);
    } catch (error) {
      console.error("API error", error);
    }
  };
  useEffect(() => {
    loadData();
  }, [params]);

  const paramsChecker = (params, type) => {
    return params.slug === type;
  }

  const frameData = {
    name: `pages.useful.${params.slug}`,
    photo: require(`../assets/images/frames/${params.slug}.png`),
    page: 'pages.useful',
    preUrl: '/materials/legislation',
    isCover: true,
    url: `/materials/${params.slug}`
  }

  return (
    <>
      <AnimatedPage>
        <section className="mb-5"><Container>
          <Frame {...frameData} />

          <p className="page-title mb-4">{t(`useful.${params.slug}.description.detail`)}</p>
          <section className="materials">
            {data?.map((item, index) => (
              <div key={index} className="material mb-4">

                {!item.document ? (
                  <a target="_blank" rel="noreferrer" href={item.document} className="material-doc mr-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                      <path d="M8 0C6.9 0 6 0.9 6 2V30C6 31.1 6.9 32 8 32H28C29.1 32 30 31.1 30 30V8L22 0H8Z" fill="#E2E5E7" />
                      <path d="M24 8H30L22 0V6C22 7.1 22.9 8 24 8Z" fill="#B0B7BD" />
                      <path d="M30 14L24 8H30V14Z" fill="#CAD1D8" />
                      <path d="M26 26C26 26.55 25.55 27 25 27H3C2.45 27 2 26.55 2 26V16C2 15.45 2.45 15 3 15H25C25.55 15 26 15.45 26 16V26Z" fill="#F15642" />
                      <path d="M6.35938 18.947C6.35938 18.683 6.56737 18.395 6.90237 18.395H8.74937C9.78937 18.395 10.7254 19.091 10.7254 20.425C10.7254 21.689 9.78937 22.393 8.74937 22.393H7.41437V23.449C7.41437 23.801 7.19037 24 6.90237 24C6.63837 24 6.35938 23.801 6.35938 23.449V18.947ZM7.41437 19.402V21.394H8.74937C9.28537 21.394 9.70937 20.921 9.70937 20.425C9.70937 19.866 9.28537 19.402 8.74937 19.402H7.41437Z" fill="white" />
                      <path d="M12.2903 24C12.0263 24 11.7383 23.856 11.7383 23.505V18.963C11.7383 18.676 12.0263 18.467 12.2903 18.467H14.1213C17.7753 18.467 17.6953 24 14.1933 24H12.2903ZM12.7943 19.443V23.025H14.1213C16.2803 23.025 16.3763 19.443 14.1213 19.443H12.7943Z" fill="white" />
                      <path d="M18.9913 19.507V20.778H21.0303C21.3183 20.778 21.6063 21.066 21.6063 21.345C21.6063 21.609 21.3183 21.825 21.0303 21.825H18.9913V23.504C18.9913 23.784 18.7923 23.999 18.5123 23.999C18.1603 23.999 17.9453 23.784 17.9453 23.504V18.962C17.9453 18.675 18.1613 18.466 18.5123 18.466H21.3193C21.6713 18.466 21.8793 18.675 21.8793 18.962C21.8793 19.218 21.6713 19.506 21.3193 19.506H18.9913V19.507Z" fill="white" />
                      <path d="M25 27H6V28H25C25.55 28 26 27.55 26 27V26C26 26.55 25.55 27 25 27Z" fill="#CAD1D8" />
                    </svg>
                  </a>
                ) : (
                  <div className="material-doc mr-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                      <path d="M8 0C6.9 0 6 0.9 6 2V30C6 31.1 6.9 32 8 32H28C29.1 32 30 31.1 30 30V8L22 0H8Z" fill="#E2E5E7" />
                      <path d="M24 8H30L22 0V6C22 7.1 22.9 8 24 8Z" fill="#B0B7BD" />
                      <path d="M30 14L24 8H30V14Z" fill="#CAD1D8" />
                      <path d="M26 26C26 26.55 25.55 27 25 27H3C2.45 27 2 26.55 2 26V16C2 15.45 2.45 15 3 15H25C25.55 15 26 15.45 26 16V26Z" fill="#666" />
                      <path d="M6.35938 18.947C6.35938 18.683 6.56737 18.395 6.90237 18.395H8.74937C9.78937 18.395 10.7254 19.091 10.7254 20.425C10.7254 21.689 9.78937 22.393 8.74937 22.393H7.41437V23.449C7.41437 23.801 7.19037 24 6.90237 24C6.63837 24 6.35938 23.801 6.35938 23.449V18.947ZM7.41437 19.402V21.394H8.74937C9.28537 21.394 9.70937 20.921 9.70937 20.425C9.70937 19.866 9.28537 19.402 8.74937 19.402H7.41437Z" fill="white" />
                      <path d="M12.2903 24C12.0263 24 11.7383 23.856 11.7383 23.505V18.963C11.7383 18.676 12.0263 18.467 12.2903 18.467H14.1213C17.7753 18.467 17.6953 24 14.1933 24H12.2903ZM12.7943 19.443V23.025H14.1213C16.2803 23.025 16.3763 19.443 14.1213 19.443H12.7943Z" fill="white" />
                      <path d="M18.9913 19.507V20.778H21.0303C21.3183 20.778 21.6063 21.066 21.6063 21.345C21.6063 21.609 21.3183 21.825 21.0303 21.825H18.9913V23.504C18.9913 23.784 18.7923 23.999 18.5123 23.999C18.1603 23.999 17.9453 23.784 17.9453 23.504V18.962C17.9453 18.675 18.1613 18.466 18.5123 18.466H21.3193C21.6713 18.466 21.8793 18.675 21.8793 18.962C21.8793 19.218 21.6713 19.506 21.3193 19.506H18.9913V19.507Z" fill="white" />
                      <path d="M25 27H6V28H25C25.55 28 26 27.55 26 27V26C26 26.55 25.55 27 25 27Z" fill="#CAD1D8" />
                    </svg>
                  </div>
                )}

                <a target="_blank" rel="noreferrer" href={item.link} className="material-name pt-1">
                  <span>
                    {item.header}
                  </span>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M1 12L23 12" stroke="#364152" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M16 5L23 12L16 19" stroke="#364152" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </span>

                </a>
              </div>
            ))}
          </section></Container>
        </section>
      </AnimatedPage>
    </>
  );
};

export default Useful;
