import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import AnimatedPage from "../config/transition";
import "swiper/css";
import "swiper/css/navigation";
import "../assets/scss/home.scss";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { useTranslation } from "react-i18next";
import Frame from "../components/Frame";
import BodiesService from "../services/bodiesService";
import { Link, useParams } from "react-router-dom";
import { t } from "i18next";
import parse from "html-react-parser";

const CommissionDetail = () => {

  var [data, setData] = useState([]);
  var [currentData, setCurrentData] = useState([]);
  const params = useParams();

  const loadBodiesData = async () => {
    try {
      const response = await BodiesService.getData();
      setData(response.result.filter((x) => x.structure?.id === params.id));

    } catch (error) {
      console.error("API error About", error);
    }
  };
  // Get the current data of the structure
  const loadStructuresData = async () => {
    try {
      const response = await BodiesService.getStructures();
      setCurrentData(response.result.find(x => x.id === params.id))

    } catch (error) {
      console.error("API error About", error);
    }
  };

  useEffect(() => {
    loadStructuresData();
    loadBodiesData();
  }, []);

  const frameData = {
    name: currentData?.name,
    photo: require('../assets/images/frames/com-detail.png'),
    page: 'pages.about.commission',
    preUrl: "/about/commission",
    url: '/about/commission',
    isCover: true,
  }
  return (
    <>
      <section className="commission detail">
        <AnimatedPage>
          <AnimatedPage>
            <div>
              <Container>

                <Frame {...frameData} />
                <Row className="commission-list py-5">
                  <Col>
                    <h4 className="commission-about font-weight-bold">
                      {t('commission.about')}
                    </h4>
                    <div className="commission-desc">
                      {parse(currentData.description??'')}
                    </div>
                    <Link to={`/about/commission/${currentData.id}/detail`} className="commission-desc mybtn active mt-2 d-inline-block my-3">
                      {t("commission.detail.button")}
                      <svg className="ml-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M12.0254 4.94168L17.0837 10L12.0254 15.0583" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M2.91602 10H16.941" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </Link>
                  </Col>
                  <Col md="6">
                    <div className="commission-photo">
                      <img src={require('../assets/images/structure.jpeg')} alt="" />
                    </div>
                  </Col>
                </Row>
              </Container>

            </div>
          </AnimatedPage>
        </AnimatedPage>
      </section>
    </>
  );
};

export default CommissionDetail;
