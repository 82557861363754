import React, { useEffect, useState } from "react";
import AnimatedPage from "../config/transition";
import { Col, Container, Row } from "react-bootstrap";
import Frame from "../components/Frame";
import LinkService from "../services/linkService";
import parse from "html-react-parser";
import { t } from "i18next";
import { Link } from "react-router-dom";
const Links = () => {
  var [data, setData] = useState([]);
  var [currentData, setCurrentData] = useState([]);
  const loadLinksData = async () => {
    try {
      const response = await LinkService.getLinks();
      setData(response.result);
    } catch (error) {
      console.error("API error About", error);
    }
  };

  useEffect(() => {
    loadLinksData();
  }, []);


  const frameData = {
    name: 'pages.useful.links',
    photo: require('../assets/images/frames/links.png'),
    page: 'pages.materials',
    preUrl: "/materials",
    url: '/materials/links'
  }

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const showLink = (item) => {
    setShow(true);
    setCurrentData(item)
  };
  return (
    <>
      <section className="links-page">
        <AnimatedPage>
          <div >
            <Container>
              <Frame {...frameData} />
              <Row>
                {data?.map((item, index) => (
                  <Col md="4" onClick={() => showLink(item)} key={index} className="mb-3">
                    <div className="link p-3">
                      <div className=" d-flex mx-0">
                        <div className="link-photo">
                          <img width="80px" src={item.photo?.photoName} alt="...." />
                        </div>
                        <div className="link-detail">
                          <div className={`link-name font-weight-bold`}>
                            {item.description}
                          </div>
                          <Link target="_blank" to={item.hyperLink} className="link-more w-100">
                            <span className=" border-0">
                              {t("go.to.link")}
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path d="M8.66797 7.33333L14.1346 1.86667" stroke="#4B5565" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M14.6648 4.53333V1.33333H11.4648" stroke="#4B5565" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                <path d="M7.33203 1.33333H5.9987C2.66536 1.33333 1.33203 2.66666 1.33203 6V10C1.33203 13.3333 2.66536 14.6667 5.9987 14.6667H9.9987C13.332 14.6667 14.6654 13.3333 14.6654 10V8.66666" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                              </svg>
                            </span>
                          </Link>
                        </div>
                      </div>

                    </div>
                  </Col>
                ))}
              </Row>
            </Container>
          </div>

        </AnimatedPage>
      </section>
    </>
  );
};

export default Links;
