import React, { useEffect, useState } from "react";
import AnimatedPage from "../config/transition";
import { Col, Container, Row } from "react-bootstrap";
import "../assets/scss/commission.scss";
import { useTranslation } from "react-i18next";
import Board from "../components/Board";
import BoardService from "../services/boardService";
import Frame from "../components/Frame";


const Boards = () => {
  var [data, setData] = useState([]);
  var [alldata, setallData] = useState([]);
  var [check, setChecker] = useState(false);
  const { t } = useTranslation();

  const loadBodiesData = async (type = false) => {
    try {
      const response = await BoardService.getBoards(type);
      setData(response.result);
      setallData(response.result);
    } catch (error) {
      console.error("API error About", error);
    }
  };

  const filterBoards = (type) => {
    setChecker(type);
    loadBodiesData(type);

  }

  useEffect(() => {
    loadBodiesData();
  }, []);

  const frameData = {
    name: 'pages.about.boards',
    photo: require('../assets/images/frames/boards.png'),
    page: 'pages.about',
    preUrl: "/about",
    url: '/about/boards'
  }
  return (
    <>
      <section className="boards">
        <AnimatedPage>
          <div >
            <Container>
              <Frame {...frameData} />
              <ul className="buttons d-flex mb-3">
                <li onClick={() => filterBoards(false)} className={`${!check ? "active" : ""} mr-3 cursor-pointer mybtn`}>
                  {t("board.members")}
                </li>
                <li onClick={() => filterBoards(true)} className={`${check ? "active" : ""} mr-3 cursor-pointer mybtn`}>
                  {t("board.check")}
                </li>
              </ul>
              <Row>
                {data?.map((item, index) => (
                  <Col md={item.order === 1 ? 12 : (item.order === 2 || item.order === 3) ? 6 : 3} key={index} className="mb-3">
                    <Board {...item} />
                  </Col>
                ))}
              </Row>
            </Container>
          </div>
        </AnimatedPage>
      </section>
    </>
  );
};

export default Boards;
