import { Container } from "react-bootstrap";
import { Link, NavLink, useLocation } from "react-router-dom";

import logo from "../assets/images/logo/logo.png";
import "../assets/scss/header.scss";
import Dropdown from 'react-bootstrap/Dropdown';
import i18n from "../config/i18";

import { useTranslation } from "react-i18next";
import routes from "../routes";
import React, { useState, useEffect } from "react";
import { useUser } from "../services/user-context";
import Socials from "./Socials";

const Header = () => {
  const { t } = useTranslation();
  const [isScrolled, setIsScrolled] = useState(false);
  const [key, setKey] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showSubMenu, setIsSubMenuOpen] = useState(0);
  const [subRoutes, setSubRoutes] = useState([])
  const { user, logout } = useUser();
  const location = useLocation();
  const [place, setPlace] = useState('header')
  useEffect(() => {
    setIsMenuOpen(false);
    setIsSubMenuOpen(0);
    setKey('')
  }, [location, t]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setIsSubMenuOpen(0)
    setSubRoutes([])
  };

  const toggleSubMenu = (key) => {
    console.log(routes)
    setSubRoutes(routes.find(x => x.id == key).collapse);
    setIsSubMenuOpen(key)

  };

  const handleScroll = () => {
    setIsSubMenuOpen(0);
    setIsMenuOpen(false);
    if (window.scrollY > 100) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    setKey(value);
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [t]);

  const changeLanguage = (lng) => {
    localStorage.setItem("language", lng);
    i18n.changeLanguage(lng.toUpperCase());
    window.location.reload();
  };


  return (
    <>
      <header className={`${isScrolled ? "fixed" : ""}`}>
        <Container>
          <div className="inner">
            <div className="top-side d-flex justify-content-between align-items-center flex-wrap">
              <div className="logo">
                <Link className="d-flex align-items-center" to={"/"}>
                  <img alt="..." src={logo} />
                  <p>{t('site.name')}</p>
                </Link>
              </div>
              <div className="top d-flex justify-content-end align-items-center">
                <div className="search">
                  <input type="text" onChange={handleChange} className="form-control" placeholder={t('search')} />
                  <Link to={key && key.length > 2 ? `/search/${key}` : ''} className="search-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z" stroke="#9AA4B2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M17.5 17.5L13.875 13.875" stroke="#9AA4B2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </Link>
                </div>
                {user?.fullname && (
                  <div className="user d-flex">
                    <div className="user-name mr-3">{user.fullname}</div>
                    <div className="notification">
                      <Link to="/notifications" >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="24"
                          viewBox="0 0 18 24"
                          fill="none"
                        >
                          <path
                            d="M16.088 10.4387C16.088 5.90584 13.72 3.18613 10.936 2.38029C10.872 1.33942 10.04 0.5 9.016 0.5C7.992 0.5 7.16 1.33942 7.096 2.38029C4.312 3.21971 1.944 5.90584 1.944 10.4387C1.944 10.4387 1.944 14.6358 0.216 17.3555C0.216 17.3555 -0.648 19.1686 1.08 19.4708H16.92C18.648 19.1686 17.784 17.3555 17.784 17.3555C16.088 14.6693 16.088 10.4387 16.088 10.4387Z"
                            fill="#2B3D5C"
                          />
                          <path
                            d="M9.016 23.5C10.616 23.5 11.896 22.1569 11.896 20.4781H6.136C6.136 22.1234 7.416 23.5 9.016 23.5Z"
                            fill="#2B3D5C"
                          />
                        </svg>
                      </Link>

                    </div>
                  </div>
                )}
                <div className="language" onClick={() => changeLanguage(i18n.language.toUpperCase() === "AZ" ? 'en' : 'az')}>
                  {i18n.language.toUpperCase() === 'AZ' ? 'EN' : 'AZ'}
                </div>

                {user?.fullname ? (
                  <div className="login">
                    <button onClick={() => logout()} className="btn mybtn">
                      <i className="fas fa-sign-in-alt"></i>
                      <span>{t("logout")}</span>
                    </button>
                  </div>
                ) : (
                  <div className="login">
                    {/* <button className="btn mybtn">
                      <Link to="/login">
                        <i className="fas fa-sign-in"></i>
                        <span>{t("login")}</span>
                      </Link>
                    </button> */}
                  </div>
                )}

                <div onClick={toggleMenu} className="mobile-menu-btn pr-0">
                  {showSubMenu < 1 ? (
                    isMenuOpen ? (
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M15 5L5 15M5 5L15 15" stroke="#697586" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M2.5 10H17.5" stroke="#697586" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M2.5 5H17.5" stroke="#697586" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M2.5 15H17.5" stroke="#697586" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    )
                  ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path d="M16.6668 10H3.3335M3.3335 10L8.3335 15M3.3335 10L8.3335 5" stroke="#697586" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  )}


                </div>
              </div>
            </div>

          </div>
        </Container>
        <nav className="w-100">
          <Container>
            <ul className="nav w-100">
              {routes?.map((route, index) => (
                <li
                  onClick={() =>
                    route.collapse ? toggleSubMenu(route.id) : ""
                  }
                  key={index}
                  className={`nav-item  dropdown ${showSubMenu === route.id ? "active" : ""
                    }`}
                >
                  {!route.collapse && (

                    <NavLink to={route.href} className="nav-link link-text">
                      {t(route.name)}
                    </NavLink>

                  )}
                  {route.collapse && (
                    <Dropdown >
                      <Dropdown.Toggle variant="basic" id="dropdown-header">
                        {t(route.name)}
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="mt-3">
                        {route.collapse.map(item => (
                          <Link
                            className={`dropdown-item`} key={item.name} to={item.href}>{t(item.name)}</Link>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  )}

                </li>
              ))}
            </ul>
          </Container>
          {/* desktop */}

          {/* mobile */}
          <div className={`${isMenuOpen ? 'active' : ''} nav mobile flex-wrap`}>
            <div className="top w-100 mb-3 d-flex">
              <div className="languages ml-0">
                <span className={`${i18n.language.toUpperCase() === 'EN' ? 'active' : ''} language d-inline-block py-1 mr-1`} onClick={() => changeLanguage('en')}>
                  EN
                </span>
                <span className={`${i18n.language.toUpperCase() === 'AZ' ? 'active' : ''} language d-inline-block py-1`} onClick={() => changeLanguage('az')}>
                  AZ
                </span>
              </div>
              <div className="search flex-grow-1">
                <Link to={key && key.length > 2 ? `/search/${key}` : ''} className="search-icon d-inline">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M21 21L17.5001 17.5M20 11.5C20 16.1944 16.1944 20 11.5 20C6.80558 20 3 16.1944 3 11.5C3 6.80558 6.80558 3 11.5 3C16.1944 3 20 6.80558 20 11.5Z" stroke="#9AA4B2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </Link>
                <input type="text" onChange={handleChange} className="form-control" placeholder={t('search')} />
              </div>
            </div>
            <ul className="w-100 flex-grow-1" >
              {routes?.map((route, index) => (
                <li

                  key={index}
                  className={`nav-item  dropdown  ${showSubMenu === route.id ? "active" : ""
                    }`}
                >
                  {route.collapse ? (
                    <div
                      onClick={() =>
                        toggleSubMenu(route.id)
                      }
                      className="nav-link link-text d-flex justify-content-between mb-3">
                      <span>{t(route.name)}</span>

                      <span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M9 18L15 12L9 6" stroke="#697586" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </svg></span>
                    </div>
                  ) : (
                    <Link to={!route.collapse ? route.href : '#'} className="nav-link link-text d-flex justify-content-between mb-3">
                      <span>{t(route.name)}</span>
                    </Link>
                  )}

                </li>
              ))}
            </ul>
            <div className="bottom w-100">
              <Socials {...{place}} />
            </div>
            {showSubMenu >0 && (
              <div className={`${showSubMenu > 0 ? 'active' : ''} mobile-sub-menu`}>
                <ul className="w-100 flex-grow-1" >
                  {subRoutes?.map((route, index) => (
                    <li
                      key={index}
                      className={`nav-item  dropdown  ${showSubMenu === route.id ? "active" : ""
                        }`}
                    >
                      <Link to={!route.collapse ? route.href : '#'} className="nav-link link-text d-flex justify-content-between mb-3">
                        <span>{t(route.name)}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}


          </div>

        </nav>

      </header >

      {isScrolled && <div className="divider"></div>
      }
    </>
  );
};

export default Header;
