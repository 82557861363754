import React, { useEffect, useState } from "react";
import "../assets/scss/links.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { useTranslation } from "react-i18next";
import { FreeMode, Pagination } from "swiper/modules";
import "swiper/css/navigation";
import LinkService from "../services/linkService";
import LinkItem from "./Link"
import { Link } from "react-router-dom";
const Links = () => {
  const { t } = useTranslation();

  const [links, setLinks] = useState([]);
  useEffect(() => {
    loadLinks();
  }, []);

  const loadLinks = async () => {
    try {
      const data = await LinkService.getLinks();
      setLinks(data.result);
    } catch (error) {
      console.error("API error", error);
    }
  };

  return (
    <>
      <Link to={'/materials/links'} className="my-3 d-block" htmlFor="">{t('links')}</Link>
        <Swiper
          autoplay={{ delay: 3000 }}
          rewind={true}
          height="100%"
          slidesPerView={4}
          loop={true}
          pagination={{
            clickable: true,
          }}
          modules={[FreeMode, Pagination]}
          breakpoints={{
            0: {
                slidesPerView: 1
            },
            768: {
                slidesPerView: 3,
            },
            1024: {
                slidesPerView: 4,
            },
        }}
          className="links-slider"
        >
            {links?.map((link, index) => (
              <SwiperSlide key={index}>
                <LinkItem {...link} />
              </SwiperSlide>
            ))}
        </Swiper>

    </>
  );
};

export default Links;