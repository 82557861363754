// src/services/newsService.js

import { get } from "./api-service";

const NotificationService = {
  getNotifications: async () => {
    try {
      const response = await get("/notifications").catch((error) => console.error(error));
      if (!response.statusCode === 200) {
        throw new Error("API error");
      }
      const data = await response;
      return data;
    } catch (error) {
      throw error;
    }
  },
  getNotificationDetail: async (id) => {
    try {
      const response = await get(`/notifications/${id}`).catch((error) =>
        console.error(error)
      );
      if (!response.statusCode === 200) {
        throw new Error("API error");
      }
      const data = await response;
      return data;
    } catch (error) {
      throw error;
    }
  },
 
};

export default NotificationService;
