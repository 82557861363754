// src/services/newsService.js

import { get } from "./api-service";

const BodiesService = {
  getData: async () => {
    try {
      const response = await get("executors").catch((error) => console.error(error));
      if (!response.statusCode === 200) {
        throw new Error("API error");
      }
      const data = await response;
      return data;
    } catch (error) {
      throw error;
    }
  },
  getStructures: async () => {
    try {
      const response = await get("structures").catch((error) => console.error(error));
      if (!response.statusCode === 200) {
        throw new Error("API error");
      }
      const data = await response;
      return data;
    } catch (error) {
      throw error;
    }
  },
};

export default BodiesService;
