// src/services/newsService.js

import { get,post } from "./api-service";

const SettingsService = {
  getSettings: async () => {
    try {
      const response = await get("settings").catch((error) =>
        console.error(error)
      );
      if (!response.statusCode === 200) {
        throw new Error("API error");
      }
      const data = await response;
      return data;
    } catch (error) {
      throw error;
    }
  },
  sendMessage: async (request) => {
    try {
      const response = await post("email",request).catch((error) =>
        console.error(error)
      );
      if (!response.statusCode === 200) {
        throw new Error("API error");
      }
      const data = await response;
      return data;
    } catch (error) {
      throw error;
    }
  },
};

export default SettingsService;
