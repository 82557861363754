import React, { useState } from "react";
import "../assets/scss/loader.scss";
import logo from "../assets/images/logo/logo-white.png";
const Loader = () => {

  const [loaading, setLoader] = useState(true)
  const removeLoader = () => {
    setLoader(false)
  }

  return (
    <>
      {loaading && (
        <div onClick={() => {
          removeLoader();
        }} className="loader">
          <div className="spinner">

            <img src={logo} alt="..." />
          </div>
        </div>
      )}
    </>
  );
};

export default Loader;
