import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import AnimatedPage from "../config/transition";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "../assets/scss/home.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import NewsService from "../services/newsService";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import parse from "html-react-parser";
import dayjs from "dayjs";
import Frame from "../components/Frame";
import { useTranslation } from "react-i18next";
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

const NewsDetail = () => {
  const params = useParams();
  const [news, setNews] = useState([]);
  const [isSpecial, setIsSpecial] = useState('');
  const [newsList, setNewsList] = useState([]);
  const lang = localStorage.getItem("language") || "az";
  const { t } = useTranslation();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const navigate = useNavigate();
  require("dayjs/locale/az")
  dayjs.locale(lang);

  const LoadNews = async () => {
    try {
      const data = await NewsService.getNewsDetail(params.id);
      if (data.result) {
        setNews(data.result);
      }
      else {
        navigate("/")
      }

      let isSpecial =    params.id == '1da34241-1dd0-4bb9-af65-3734c9e6a5bd'
      setIsSpecial(isSpecial)
      document.title = data.result.header;
      const metaDescription = document.createElement("meta");
      metaDescription.name = "description";
      metaDescription.content = data.result.header;
      document.head.appendChild(metaDescription);

      const existingOGTags = document.querySelectorAll("meta[property^='og:']");
      existingOGTags.forEach(tag => tag.remove());

      const ogTitle = document.createElement("meta");
      ogTitle.property = "og:title";
      ogTitle.content = data.result.header;
      document.head.appendChild(ogTitle);

      const ogDescription = document.createElement("meta");
      ogDescription.property = "og:description";
      ogDescription.content = data.result.header;
      document.head.appendChild(ogDescription);

      const ogImage = document.createElement("meta");
      ogImage.property = "og:image";
      ogImage.content = data.result.photo;
      document.head.appendChild(ogImage);

      const ogURL = document.createElement("meta");
      ogURL.property = "og:url";
      ogURL.content = `https://judge.az/news/detail/${data.result.id}`;
      document.head.appendChild(ogURL);
    } catch (error) {
      console.error("API error Event", error);
    }
  };

  const LoadNewsList = async () => {
    try {
      const data = await NewsService.getNews();
      setNewsList(data.result.filter((x => x.id !== news.id)).splice(0, 10));

    } catch (error) {
      console.error("API error Event", error);
    }
  };
  useEffect(() => {

    LoadNews(params.id);
    LoadNewsList();
  }, [params]);


  const shareOnFacebook = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(`https://judge.az/news/detail/${news.id}`)}`, '_blank');
  };

  const shareOnLinkedIn = () => {
    window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(`https://judge.az/news/detail/${news.id}`)}`, '_blank');
  };
  const shareOnXIn = () => {
    window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(`https://judge.az/news/detail/${news.id}`)}&text=${encodeURIComponent(news.header)}`);
  };

  const frameData = {
    name: 'pages.news',
    photo: require('../assets/images/frames/news.png'),
    page: 'pages.news',
    isCover: true,
    url: '/news'
  }


  return (
    <>
      <AnimatedPage>
        <section className="news-detail-page news-detail ">
          <Container>
            <Frame {...frameData} />
            <Row>
              <Col md="8" className="my-3">
                <div className="news">
                  <Row>

                    <Col md={{ order: 1, span: 12 }}>
                      <div className="news-header">
                        {news.header}
                        <div className="news-date">
                          {dayjs(news.createTime).format("DD MMMM  YYYY")}
                        </div>
                      </div>
                    </Col>

                    {isSpecial && (
                      <Col className="mt-3" md={{ order: 1, span: 12 }} xs={{ order: 2, span: 12 }}>
                        <Row >
                          <Col md="4">
                            <iframe width="100%" src="https://www.youtube.com/embed/7wQLRYAxkLs?si=etD-Hbir21Gx6dYd" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                          </Col>
                          <Col md="4">
                            <iframe width="100%" src="https://www.youtube.com/embed/dyZP-pvJIJc?si=CWR6AcWerLn-sT-f" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> </Col>
                          <Col md="4">
                            <iframe width="100%" src="https://www.youtube.com/embed/q-s-wCYUyy8?si=jeH5anLoZHBVyenW" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                          </Col>
                        </Row>

                      </Col>
                    )}


                    <Col md={{ order: 1, span: 12 }} xs={{ order: 2, span: 12 }}>
                      <div className="news-detail">{
                        parse(news.description ?? '')
                      }</div>
                    </Col>
                    <Col md={{ order: 1, span: 12 }}>

                      {news && news.photo && (
                        <>
                          <div className="news-slider">

                            <Swiper
                              style={{
                                '--swiper-navigation-color': '#fff',
                                '--swiper-pagination-color': '#fff',
                              }}
                              loop={true}
                              spaceBetween={10}
                              navigation={true}
                              thumbs={{ swiper: thumbsSwiper }}
                              modules={[FreeMode, Navigation, Thumbs]}
                              className="mySwiper2 mt-3"
                            >
                              <SwiperSlide >
                                <img src={news.photo} />
                              </SwiperSlide>
                              {news && news.photos && news.photos.map((value, index) => (
                                <SwiperSlide key={index}>
                                  <img src={value.link} />
                                </SwiperSlide>
                              ))}
                            </Swiper>
                            <Swiper
                              onSwiper={setThumbsSwiper}
                              loop={true}
                              spaceBetween={10}
                              slidesPerView={4}
                              freeMode={true}
                              watchSlidesProgress={true}
                              modules={[FreeMode, Navigation, Thumbs]}
                              className="mySwiper mt-3"
                            >

                              <SwiperSlide >
                                <img src={news.photo} />
                              </SwiperSlide>
                              {news && news.photos && news.photos.map((value, index) => (
                                <SwiperSlide key={index}>
                                  <img src={value.link} />
                                </SwiperSlide>
                              ))}



                            </Swiper>
                          </div>
                        </>
                      )}
                    </Col>

                    <Col md={{ order: 1, span: 12 }}>

                      <div className="news-share">
                        <div className="d-flex justify-content-between w-100">
                          <div>
                            <label htmlFor="share">{t("share.on.social")}</label>
                          </div>
                          <div className="socials">
                            <ul className="socials-list">
                              <li className="socials-list-item">
                                <div onClick={shareOnFacebook}>
                                  <i className="fab fa-facebook-f"></i>
                                </div>
                              </li>
                              <li className="socials-list-item">
                                <div onClick={shareOnLinkedIn}>
                                  <i className="fab fa-linkedin-in"></i>
                                </div>
                              </li>
                              <li className="socials-list-item">
                                <div onClick={shareOnXIn}>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M1.73998 1.86279L6.80245 8.6318L1.70801 14.1353H2.85456L7.31474 9.3169L10.9184 14.1353H14.8202L9.47289 6.98553L14.2148 1.86279H13.0682L8.96061 6.30043L5.64174 1.86279H1.73998ZM3.42607 2.70735H5.21855L13.1338 13.2906H11.3414L3.42607 2.70735Z" fill="#697586" />
                                  </svg>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>

                      </div>
                    </Col>
                  </Row>
                </div >
              </Col>
              <Col md="4" className="mb-3">
                <div className="news-lists">
                  <Link className="my-3 d-block label" to="/news">{t('news.and.announces')}</Link>

                  <div className="news-list">
                    {newsList && newsList.map((value, index) => (
                      <Link key={index} to={`/news/detail/${value.id}`} className="news mb-3 w-100 d-block cp">
                        <div className="news-title">{value.header}...</div>
                        <div className="news-desc">{parse(value.description ?? '')}...</div>
                      </Link>
                    ))}

                  </div>

                </div>
              </Col>
            </Row>


          </Container>
        </section>
      </AnimatedPage>
    </>
  );
};

export default NewsDetail;
