import React from "react";

import "../assets/scss/notifications.scss";
import parse from "html-react-parser";
import { Col, Row } from "react-bootstrap";
import dayjs from "dayjs";
const Notification = ({ description ,createTime}) => {
  return (
    <>
      <div className="notification mt-3">
        <div className="notification-header">
          <Row>
            <Col md="10" className="mb-2">
            {parse(description ?? '')}
            </Col>
            <Col md="2" className="text-right">
            {dayjs(createTime).format("DD MMM YYYY")}
            </Col>
          </Row>
          
         
      </div>
      </div>
    </>
  );
};

export default Notification;
