import React from "react";

const LinkItem = ({ id, description, photo, hyperLink }) => {


  return (
    <a target="_blank" rel="noreferrer" href={hyperLink} className="link w-100">
      <div className="d-flex align-items-center justify-content-center h-100 flex-wrap">
        <div>
          <div className={`link-photo text-center h-100 ${id === 4 ? 'p-1' : ''}`}>
            <img src={photo.photoName} alt={photo.photoName} />
          </div>
          <div className="link-detail w-100 text-center">
            <p>{description}</p>
          </div>
          <div className="link-url w-100 text-center">
            <p>{hyperLink}     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <path d="M8.66797 7.33333L14.1346 1.86667" stroke="#4B5565" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M14.6648 4.53333V1.33333H11.4648" stroke="#4B5565" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
              <path d="M7.33203 1.33333H5.9987C2.66536 1.33333 1.33203 2.66666 1.33203 6V10C1.33203 13.3333 2.66536 14.6667 5.9987 14.6667H9.9987C13.332 14.6667 14.6654 13.3333 14.6654 10V8.66666" stroke="#292D32" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg></p>
          </div>
        </div>
      </div>
    </a>
  );
};

export default LinkItem;
