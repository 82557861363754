// src/services/newsService.js

import { post } from "./api-service";

const SearchService = {
  getSearchData: async (request) => {
    const requestdata = request;
    try {
      const response = await post(`search?key=${requestdata}`).catch((error) =>
        console.error(error)
      );
      if (!response.statusCode === 200) {
        throw new Error("API error");
      }
      const data = await response;
      return data;
    } catch (error) {
      throw error;
    }
  },
};

export default SearchService;
