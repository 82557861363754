// src/services/newsService.js

import { get, post } from "./api-service";

const MaterilsService = {
  getMaterials: async () => {
    try {
      const response = await get("useful").catch((error) => console.error(error));
      if (!response.statusCode === 200) {
        throw new Error("API error");
      }
      const data = await response;
      return data;
    } catch (error) {
      throw error;
    }
  },
  getMaterialsByType: async (payload) => {
    try {
      const response = await post("usefuls/types/false",payload).catch((error) => console.error(error));
      if (!response.statusCode === 200) {
        throw new Error("API error");
      }
      const data = await response;
      return data;
    } catch (error) {
      throw error;
    }
  },
  getUsefulsByType: async () => {
    try {
      const response = await get("usefuls").catch((error) => console.error(error));
      if (!response.statusCode === 200) {
        throw new Error("API error");
      }
      const data = await response;
      return data;
    } catch (error) {
      throw error;
    }
  },
  getActs: async () => {
    try {
      const response = await get("acts").catch((error) => console.error(error));
      if (!response.statusCode === 200) {
        throw new Error("API error");
      }
      const data = await response;
      return data;
    } catch (error) {
      throw error;
    }
  },
 
};

export default MaterilsService;
