import React, { createContext, useState, useEffect, useContext } from "react";
import { get } from "../services/api-service";
import i18n from 'i18next';

const DataContext = createContext();

export const useData = () => useContext(DataContext);

export const DataProvider = ({ children }) => {
  const [data, setData] = useState([]);
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'az');

  useEffect(() => {
    i18n.init({
      lng: language,
      resources: {
        [language]: {
          translation: data,
        },
      },
    });
  }, [data, language]);

  useEffect(() => {
    localStorage.setItem('language', language);
  }, [language]);

  useEffect(() => {
    get('translates')
      .then((response) => {
        if (response && response.statusCode === 200) {
          setData(response.result);
        }
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <DataContext.Provider value={{ data, language, setLanguage }}>
      {children}
    </DataContext.Provider>
  );
};
