import React, { useEffect, useState } from "react";
import { Container, Row } from "react-bootstrap";
import AnimatedPage from "../config/transition";
import "swiper/css";
import "swiper/css/navigation";

import "../assets/scss/home.scss";
import { useParams } from "react-router-dom";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import BoardService from "../services/boardService";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import Frame from "../components/Frame";


const BoardDetail = () => {

  const { t } = useTranslation();
  const params = useParams();

  const [board, setBoard] = useState([]);

  useEffect(() => {
    const LoadActivity = async () => {
      try {
        const data = await BoardService.getBoardDetail(params.id);
        setBoard(data.result);
      } catch (error) {
        console.error("API error Event", error);
      }
    };

    LoadActivity(params.id);
  }, [params]);

  const frameData = {
    name: 'pages.about.boards',
    photo: require('../assets/images/frames/boards.png'),
    page: 'pages.about',
    preUrl:"/about",
    url: '/about/boards'
  }
  return (
    <>
      <AnimatedPage>
        <section className="board-detail">
          <Container>
            <Frame {...frameData} />
            <div className="board mb-5">
              <Row>
                <div className="col-md-12">
                  <div className="board-photo">
                    <img src={board?.photo?.photoName} alt="..." />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="board-info">
                    <div className="board-fullname">
                      <p className="font-weight-bold">{board?.fullname}</p>
                    </div>
                    <div className="board-position">
                      <p>{board?.position}</p>
                    </div>
                  
                  </div>
                  <div className="board-description">
                      {parse(board?.description ?? '')}

                  </div>
                </div>
              </Row>
            </div>
          </Container>
        </section>
      </AnimatedPage>
    </>
  );
};

export default BoardDetail;
