import React, { useEffect, useState } from "react";
import AnimatedPage from "../config/transition";
import "../assets/scss/activity.scss";
import { Link, useParams } from "react-router-dom";
import SearchService from "../services/searchService";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "../assets/scss/search.scss";
import parse from "html-react-parser";

const Search = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [showError, setShowError] = useState(false)
  const params = useParams();

  useEffect(() => {
    const search = async (key) => {
      setShowError(false)
      try {
        const response = await SearchService.getSearchData(key);

        if (response.result && response.result?.length) {

          setData(response.result);
        } else {
          setTimeout(() => {
            setShowError(true)
          }, 1000);
        }

      } catch (error) {
        setShowError(false)
        console.error("Error submitting form:", error);
      }
    };

    if (params.key) {
      search(params.key);
    }
  }, [params]);
  return (
    <>
      <AnimatedPage>
        <Container>
          <section className="search-items">
            {data && data.length > 0 && (
              <h4 className="search-title">
                "{params.key}"  {t('search.results')}:
              </h4>
            )}

            {data && data.length > 0 ? (

              data?.map((item, index) => (
                <div to={item?.link} key={index} className=" mb-3">
                  <div className="search-item">
                    <div className="search-item-content">
                      <div className="search-item-title">
                        {item?.title}

                      </div>
                      {item.body && item.body.map((value, index)=>(
                        <div key={index} className="search-item-desc">
                          <Link  to={value.link}>
                            {parse(value?.subject ?? '')}
                          </Link>
                        </div>
                      ))}

                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="no-data-message d-flex justify-content-center w-100 flex-wrap">
                <svg xmlns="http://www.w3.org/2000/svg" width="256" height="256" viewBox="0 0 256 256" fill="none">
                  <path fillRule="evenodd" clipRule="evenodd" d="M176.103 185H94.098C88.3262 185 83.6016 180.261 83.6016 174.47V57.5275C83.6016 51.7362 88.3262 47 94.098 47H176.103C181.877 47 186.602 51.7362 186.602 57.5275V174.47C186.602 180.261 181.877 185 176.103 185Z" fill="#E0E2EE" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M169.103 190H87.098C81.3262 190 76.6016 185.261 76.6016 179.47V62.5275C76.6016 56.7362 81.3262 52 87.098 52H169.103C174.877 52 179.602 56.7362 179.602 62.5275V179.47C179.602 185.261 174.877 190 169.103 190Z" fill="#E8EBF2" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M162.294 193.947H80.1197C74.3359 193.947 69.6016 189.228 69.6016 183.461V67.0025C69.6016 61.2352 74.3359 56.5186 80.1197 56.5186H162.294C168.08 56.5186 172.814 61.2352 172.814 67.0025V183.461C172.814 189.228 168.08 193.947 162.294 193.947Z" fill="#D8DBEA" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M162.294 193.947H80.1197C74.3359 193.947 69.6016 189.228 69.6016 183.461V67.0025C69.6016 61.2352 74.3359 56.5186 80.1197 56.5186H162.294C168.08 56.5186 172.814 61.2352 172.814 67.0025V183.461C172.814 189.228 168.08 193.947 162.294 193.947Z" fill="#F1F2F7" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M150.449 92H89.7538C88.0204 92 86.6016 90.6502 86.6016 89.0011C86.6016 87.3498 88.0204 86 89.7538 86H150.449C152.183 86 153.602 87.3498 153.602 89.0011C153.602 90.6502 152.183 92 150.449 92Z" fill="#E0E2EE" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M150.449 110H89.7538C88.0204 110 86.6016 108.65 86.6016 107.001C86.6016 105.35 88.0204 104 89.7538 104H150.449C152.183 104 153.602 105.35 153.602 107.001C153.602 108.65 152.183 110 150.449 110Z" fill="#E0E2EE" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M150.449 128H89.7538C88.0204 128 86.6016 126.65 86.6016 125.001C86.6016 123.352 88.0204 122 89.7538 122H150.449C152.183 122 153.602 123.352 153.602 125.001C153.602 126.65 152.183 128 150.449 128Z" fill="#E0E2EE" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M150.449 146H89.7538C88.0204 146 86.6016 144.65 86.6016 143.001C86.6016 141.35 88.0204 140 89.7538 140H150.449C152.183 140 153.602 141.35 153.602 143.001C153.602 144.65 152.183 146 150.449 146Z" fill="#E0E2EE" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M121.436 164H89.7672C88.0265 164 86.6016 162.65 86.6016 161.001C86.6016 159.35 88.0265 158 89.7672 158H121.436C123.177 158 124.602 159.35 124.602 161.001C124.602 162.65 123.177 164 121.436 164Z" fill="#E0E2EE" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M185.357 140.737C173.22 152.871 154.431 154.24 140.79 144.872C139.047 143.653 137.387 142.284 135.84 140.737C135.044 139.943 134.293 139.124 133.585 138.261C132.17 136.538 130.931 134.701 129.916 132.803C128.126 129.597 126.91 126.195 126.246 122.679C124.103 111.651 127.307 99.7808 135.84 91.2517C144.394 82.6971 156.266 79.514 167.295 81.6361C170.81 82.3206 174.215 83.5368 177.421 85.3052C179.323 86.3434 181.135 87.5826 182.86 88.9973C183.72 89.7021 184.54 90.4553 185.334 91.2517C186.883 92.7987 188.273 94.4552 189.469 96.2007C198.842 109.837 197.47 128.625 185.357 140.737Z" fill="white" fillOpacity="0.1" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M181.96 138.347C169.334 150.976 148.871 150.973 136.242 138.347C123.63 125.734 123.63 105.27 136.259 92.643C148.871 80.0305 169.334 80.0305 181.946 92.643C194.573 105.27 194.573 125.734 181.96 138.347ZM188.441 86.1555C172.233 69.9482 145.97 69.9482 129.762 86.1555C113.557 102.361 113.543 128.64 129.748 144.848C144.499 159.594 167.619 160.924 183.88 148.816C185.468 147.632 186.998 146.305 188.457 144.848C189.914 143.389 191.241 141.859 192.423 140.271C204.53 124.007 203.189 100.904 188.441 86.1555Z" fill="#E0E2EE" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M252.224 205.942L251.543 206.622C247.042 211.126 239.67 211.126 235.166 206.622L193.602 165.057L210.659 148L252.224 189.565C256.728 194.069 256.728 201.438 252.224 205.942Z" fill="#6AA2F9" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M193.319 139L205.602 151.283L196.882 160L184.602 147.717C186.222 146.509 187.781 145.154 189.272 143.668C190.758 142.182 192.113 140.621 193.319 139Z" fill="#E0E2EE" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M251.602 189.054L234.653 206L231.602 202.946L248.548 186L251.602 189.054Z" fill="#4C84DB" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M211.602 148.587L194.189 166L191.602 163.413L209.014 146L211.602 148.587Z" fill="#4C84DB" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M173.602 88.2986L130.898 131C129.29 128.118 128.196 125.059 127.602 121.898L164.499 85C167.658 85.6176 170.719 86.7088 173.602 88.2986Z" fill="white" fillOpacity="0.5" />
                  <path fillRule="evenodd" clipRule="evenodd" d="M187.602 98.4484L141.434 142C139.781 140.912 138.207 139.687 136.74 138.302C135.985 137.59 135.27 136.859 134.602 136.087L181.331 92C182.149 92.633 182.926 93.307 183.679 94.0197C185.147 95.4041 186.467 96.8864 187.602 98.4484Z" fill="white" fillOpacity="0.5" />
                </svg>

                <div className="w-100 text-center">
                  <p>
                    "{params.key}" {t('notfound')}.
                  </p>
                  <Link to="/" className="btn btn-outline-primary p-3 px-5 mt-4">{t('pages.main')}</Link>
                </div>
              </div>
            )
            }
          </section>
        </Container>
      </AnimatedPage>
    </>
  );
};

export default Search;
