import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination } from "swiper/modules";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import GalleryService from "../services/galleryService"

import "swiper/css";
import 'swiper/css/free-mode';

const GalleryList = () => {
    const { t } = useTranslation();

    const [galleries, setGalleries] = useState([]);


    const loadGalleries = async () => {
        try {
            const data = await GalleryService.getGalleries();
            setGalleries(data.result.splice(0,9));
        } catch (error) {
            console.error("API error", error);
        }
    };

    useEffect(() => {
        loadGalleries();
    }, []);

    return (
        <>
            <Container>
                <div className="d-flex justify-content-between">

                    <Link className="my-3 d-block label" to="/gallery">{t('pages.gallery')}</Link>
                    <Link className="my-3 d-block " to="/gallery">{t('show.all')} <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M12.0249 4.94141L17.0832 9.99974L12.0249 15.0581" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M2.9165 10H16.9415" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                    </svg></Link>
                </div>
                <Swiper
                    autoplay={{ delay: 3000 }}
                    rewind={true}
                    height="100%"
                    spaceBetween={10}
                    slidesPerView={2}
                    freeMode={true}
                    loop={true}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[FreeMode, Pagination]}
                    className="gallery-slider"
                    breakpoints={{
                        0: {
                            slidesPerView: 'auto'
                        },
                        768: {
                            slidesPerView: 3,
                        },
                        1024: {
                            slidesPerView: 4,
                        },
                    }}
                >
                    {galleries?.map((g, index) => (
                        <SwiperSlide key={index} className="gallery">
                            <Link to="/gallery" className="gallery-item">
                                <img src={g.photo} />

                            </Link>
                        </SwiperSlide>
                    ))}

                </Swiper>
            </Container>

        </>
    );
};

export default GalleryList;