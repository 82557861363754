const routes = [
  {
    id:0,
    name: "pages.main",
    href: "/",
  },
  {
    
    id: 1,
    name: "pages.about",
    href: "/about",
    collapse: [
      {
        name: "pages.general",
        href: "/about",
      },
      {
        name: "pages.about.boards",
        href: "/about/boards",
      },
      {
        name: "pages.about.commission",
        href: "/about/commission",
      },
      {
        name: "pages.about.constitution",
        href: "/about/constitution",
      },
      {
        name: "pages.about.partners",
        href: "/about/partners",
      },
    ],
  },
  {
    id: 2,
    name: "pages.activity",
    href: "/activity/internalcom",
    collapse: [
      {
        name: "pages.activity.internalcom",
        href: "/activity/internalcom",
      },
      {
        name: "pages.activity.seminars",
        href: "/activity/seminars",
      },
      {
        name: "pages.activity.personal",
        href: "/activity/personal",
      },
      {
        name: "pages.activity.copyrights",
        href: "/activity/copyrights",
      },
      {
        name: "pages.activity.leisure",
        href: "/activity/leisure",
      },
      {
        name: "pages.activity.social",
        href: "/activity/social",
      },

    ],
  },
  {
    id: 5,
    name: "pages.materials",
    href: "/materials/legislation",
    collapse: [
      {
        name: "pages.useful.legislation",
        href: "/materials/legislation",
      },
      {
        name: "pages.useful.internaldoc",
        href: "/materials/internaldoc",
      },
      {
        name: "pages.useful.publications",
        href: "/materials/publications",
      },
      {
        name: "pages.useful.links",
        href: "/materials/links",
      },
    ],
  },
  {
    id: 6,
    name: "pages.news",
    href: "/news",
  },
  {
    id: 7,
    name: "pages.gallery",
    href: "/gallery",
  },
  {
    id: 8,
    name: "pages.contact",
    href: "/contact",
  },
];

export default routes;
