import React, { useEffect, useState } from "react";

import News from "./News";
import "../assets/scss/news.scss";
import newsService from "../services/newsService";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "../assets/scss/home.scss";
const NewsList = () => {
  const [news, setNews] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    loadNews();
  }, []);

  const loadNews = async () => {
    const d = {
      isNews: true,
    }
    try {
      const data = await newsService.getNews();
      setNews(data.result.splice(0, 9));
    } catch (error) {
      console.error("API error", error);
    }
  };
  return (
    <>

      <div className="d-flex justify-content-between">
        <Link className="my-3 d-block label" to="/news">{t('news.and.announces')}</Link>
        <Link className="my-3 d-block " to="/news">{t('show.all')} <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path d="M12.0249 4.94141L17.0832 9.99974L12.0249 15.0581" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M2.9165 10H16.9415" stroke="#292D32" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        </Link>
      </div>
      <Swiper
        autoplay={{ delay: 3000 }}
        rewind={true}
        height="100%"
        slidesPerView={3}
        spaceBetween={10}
        loop={true}
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode, Pagination]}
        breakpoints={{
          0: {
            slidesPerView: 'auto'
          },
          768: {
            slidesPerView: 2,
          },
          1024: {
            slidesPerView: 3,
          },
        }}
        className="news-slider"
      >
        {news?.map((item, index) => (
          <Col key={index} md="4" className="mb-3">
            <SwiperSlide key={index}>
              <News {...item} />
            </SwiperSlide>
          </Col>
        ))}
      </Swiper>
    </>
  );
};

export default NewsList;
