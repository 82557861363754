import React, { useEffect, useState } from "react";
import AnimatedPage from "../config/transition";
import { Container, Row } from "react-bootstrap";
import { useUser } from "../services/user-context";
import { Link, useNavigate } from "react-router-dom";
import NotificationService from "../services/notificationService";
import Notification from "../components/Notification";

const Notifications = () => {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const { user } = useUser();
  if (!user) {
      navigate("/login")
  }
  const LoadData = async () => {
    try {
      const data = await NotificationService.getNotifications();
      setData(data.result);
    } catch (error) {
      console.error("API error About", error);
    }
  };
  useEffect(() => {
    LoadData();
  }, [user]);


  return (
    <>
      <AnimatedPage>
        <section className="mb-5">
          <Container>
            <section className="notifications">
              <Row>
                {data?.map((item, index) => (
                  <Link to={`/notifications/${item.id}`} key={index} className="col-md-12">
                    <Notification {...item} />
                  </Link>
                ))}
              </Row>
            </section>
          </Container>
        </section>
      </AnimatedPage>
    </>
  );
};

export default Notifications;
