// src/services/newsService.js

import { get } from "./api-service";

const LinkService = {
  getLinks: async () => {
    try {
      const response = await get("link").catch((error) => console.error(error));
      if (!response.statusCode === 200) {
        throw new Error("API error");
      }
      const data = await response;
      return data;
    } catch (error) {
      throw error;
    }
  },
  getLinksDetail: async (id) => {
    try {
      const response = await get(`link/${id}`).catch((error) =>
        console.error(error)
      );
      if (!response.statusCode === 200) {
        throw new Error("API error");
      }
      const data = await response;
      return data;
    } catch (error) {
      throw error;
    }
  },
};

export default LinkService;
