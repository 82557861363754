import React, { useEffect, useState } from "react";
import NewsItem from "../components/News";
import { Container, Row } from "react-bootstrap";
import AnimatedPage from "../config/transition";
import newsService from "../services/newsService";
import Frame from "../components/Frame";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const Activities = () => {
  const [news, setNews] = useState([]);

  const { t } = useTranslation();
  const params = useParams();
  const loadNews = async () => {
    const d = {
      isConferences: paramsChecker(params, "seminars"),
      isInternationalRelations: paramsChecker(params, "internalcom"),
      isLeisureTime: paramsChecker(params, "leisure"),
      isPersonalDevelopment: paramsChecker(params, "personal"),
      isProtectRight: paramsChecker(params, "isNecopyrightsws"),
      isSocialAssistance: paramsChecker(params, "social"),
    }

    try {
      const data = await newsService.getNewsByType(d);
      setNews(data.result);
    } catch (error) {
      console.error("API error", error);
    }
  };
  useEffect(() => {
    loadNews();
  }, [params]);


  const paramsChecker = (params, type) => {
    return params.slug === type;
  }

  const frameData = {
    name: `pages.activity.${params.slug}`,
    photo: require(`../assets/images/frames/${params.slug}.png`),
    page: 'pages.activity',
    preUrl: '/activity/internalcom',
    isCover: params.slug === 'leisure' || params.slug === 'social' ? false : true,
    url: `/activity/${params.slug}`
  }

  return (
    <>
      <AnimatedPage>
        <Container>
          <Frame {...frameData} />

          <p className="page-title">{t(`activity.${params.slug}.description.detail`)}</p>

          <section className="newsList py-3">  <Row>    {news?.map((item) => (<div key={item.id} className="col-md-4 mb-4">        <NewsItem {...item} />      </div>))}  </Row></section>
        </Container>
      </AnimatedPage>
    </>
  );
};

export default Activities;
