import React from "react";
import "../assets/scss/partners.scss";
import { t } from "i18next";

const Partner = ({ id, fullname, order, photo, header }) => {
  
  return (
    <>
      <div  className="partner p-3">
        <div className=" d-flex mx-0">
          <div className="partner-photo">
            <img width="80px" src={photo?.photoName} alt="...." />
          </div>
          <div className="partner-detail">
            <div className={`partner-name font-weight-bold`}>
              {header}
            </div>
            <div className="partner-more w-100">
              <span className="mybtn border-0 pl-0">
                {t("more")}
                <svg className="ml-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M12.0254 4.94167L17.0837 10L12.0254 15.0583" stroke="#202939" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                  <path d="M2.91602 10H16.941" stroke="#202939" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </span>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default Partner;
