import React from "react";
import '../assets/scss/components/frame.scss'
import { t } from "i18next";
import { Link } from "react-router-dom";

const Frame = ({ url, preUrl, name, photo, page, isCover }) => {


    return (
        <>
            <div className="frame">
                <div className="frame-photo">
                    <img className={isCover ? "isCover" : ''} src={photo} alt="..." />
                </div>
                <div className="frame-detail">
                    <div className="frame-name">{t(name)}</div>
                    <div className="frame-url">
                        <Link to='/'>
                            {t("pages.main")}
                        </Link>
                        <i className="fa fa-chevron-right"></i>

                        {preUrl && (
                            <>
                                <Link to={preUrl}>
                                    {t(page)}
                                </Link>
                                <i className="fa fa-chevron-right"></i>
                            </>
                        )}
                        <Link to={url}>
                            {t(name)}
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Frame;
