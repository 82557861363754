import React from "react";
import '../assets/scss/components/socials.scss'

const Socials = ({place}) => {

    const data = localStorage.getItem("data")
        ? JSON.parse(localStorage.getItem("data"))
        : null;
    return (
        <>
            <ul className={`${place} socials`}>
                {data?.youtube && (

                <li className="mr-3 socials-item">
                    <a
                        target="_blank"
                        rel="noreferrer"
                    >
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="YouTube">
                                <g id="Group">
                                    <path id="Vector" d="M19.5881 5.19109C19.3574 4.33443 18.682 3.65898 17.8253 3.42833C16.2602 3 9.99996 3 9.99996 3C9.99996 3 3.73969 3 2.17462 3.41186C1.33443 3.6425 0.642501 4.33443 0.41186 5.19109C0 6.75616 0 10.0016 0 10.0016C0 10.0016 0 13.2635 0.41186 14.8121C0.642501 15.6688 1.31795 16.3443 2.17462 16.5749C3.75616 17.0032 9.99996 17.0032 9.99996 17.0032C9.99996 17.0032 16.2602 17.0032 17.8253 16.5914C18.682 16.3607 19.3574 15.6853 19.5881 14.8286C19.9999 13.2635 19.9999 10.0181 19.9999 10.0181C19.9999 10.0181 20.0164 6.75616 19.5881 5.19109Z" fill="#fff" />
                                    <path id="Vector_2" d="M8.00586 13.0001L13.2118 10.0018L8.00586 7.00342V13.0001Z" fill="#697586" />
                                </g>
                            </g>
                        </svg>
                    </a>
                </li>
                )}

                {data?.instagram && (
                <li className="mr-3 socials-item">
                    <a
                        target="_blank"
                        rel="noreferrer"
                    >
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="Frame 427323115">
                                <path id="Vector" d="M8.54922 11.3992C10.1232 11.3992 11.3992 10.1232 11.3992 8.54922C11.3992 6.97521 10.1232 5.69922 8.54922 5.69922C6.97521 5.69922 5.69922 6.97521 5.69922 8.54922C5.69922 10.1232 6.97521 11.3992 8.54922 11.3992Z" fill="#fff" />
                                <path id="Subtract" fillRule="evenodd" clipRule="evenodd" d="M4.98679 0H12.1118C14.862 0 17.1 2.23725 17.1 4.98679V12.1118C17.1 14.862 14.8628 17.1 12.1132 17.1H4.98821C2.23796 17.1 0 14.8628 0 12.1132V4.98821C0 2.23796 2.23725 0 4.98679 0ZM4.275 8.55C4.275 10.9077 6.19234 12.825 8.55 12.825C10.9077 12.825 12.825 10.9077 12.825 8.55C12.825 6.19234 10.9077 4.275 8.55 4.275C6.19234 4.275 4.275 6.19234 4.275 8.55ZM13.5508 4.54883C14.1031 4.54883 14.5508 4.10111 14.5508 3.54883C14.5508 2.99654 14.1031 2.54883 13.5508 2.54883C12.9985 2.54883 12.5508 2.99654 12.5508 3.54883C12.5508 4.10111 12.9985 4.54883 13.5508 4.54883Z" fill="#fff" />
                            </g>
                        </svg>

                    </a>
                </li>
                )}
                {data?.facebook && (
                <li className="mr-3 socials-item">
                    <a
                        target="_blank"
                        rel="noreferrer"
                    >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="Social platforms logo">
                                <g id="Logo">
                                    <path id="Path 17" d="M13 13.2H15.1429L16 10H13V8.4C13 7.576 13 6.8 14.7143 6.8H16V4.112C15.7206 4.0776 14.6654 4 13.5511 4C11.224 4 9.57143 5.3256 9.57143 7.76V10H7V13.2H9.57143V20H13V13.2Z" fill="#fff" />
                                </g>
                            </g>
                        </svg>

                    </a>
                </li>
                )}
                {data?.twitter && (
                <li className="mr-3 socials-item">
                    <a
                        target="_blank"
                        rel="noreferrer"
                    >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="Social icon">
                                <path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M14.7465 20L10.8829 14.2473L6.04622 20H4L9.97508 12.8953L4 4H9.25355L12.8949 9.42183L17.4573 4H19.5036L13.8058 10.7756L20 20H14.7465ZM17.0252 18.3782H15.6475L6.92988 5.62182H8.30767L11.7992 10.7296L12.4029 11.6159L17.0252 18.3782Z" fill="#fff" />
                            </g>
                        </svg>

                    </a>
                </li>
                )}
                {data?.linkedin && (
                <li className="mr-3 socials-item">
                    <a
                        target="_blank"
                        rel="noreferrer"
                    >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g id="LinkedIn">
                                <path id="Path 225" fillRule="evenodd" clipRule="evenodd" d="M7.67761 4.82621C7.6771 5.83429 6.85343 6.6511 5.83789 6.65059C4.82234 6.65009 3.99949 5.83247 4 4.82438C4.00051 3.8163 4.82418 2.9995 5.83972 3C6.85527 3.0005 7.67812 3.81813 7.67761 4.82621ZM7.7323 8.00244H4.05469V19.4288H7.7323V8.00244ZM9.88477 8.00209H13.544L13.5072 9.53534C15.0334 6.77002 21.5244 6.56011 21.5244 12.1911V19.4284H17.8928V13.4323C17.8928 9.78176 13.5072 10.0921 13.5072 13.4323V19.4284H9.88477V8.00209Z" fill="#fff" />
                            </g>
                        </svg>

                    </a>
                </li>
                )}
            </ul>
        </>
    );
};

export default Socials;
