import React, { useEffect, useState } from "react";
import AnimatedPage from "../config/transition";
import { Col, Container, Row } from "react-bootstrap";
import BodiesService from "../services/bodiesService";
import "../assets/scss/commission.scss";
import Frame from "../components/Frame";
import { Link } from "react-router-dom";

const Commission = () => {
  var [structures, setStructures] = useState([]);

 
  const loadStructuresData = async () => {
    try {
      const response = await BodiesService.getStructures();
      setStructures(response.result);
    } catch (error) {
      console.error("API error About", error);
    }
  };

  useEffect(() => {
    loadStructuresData();
  }, []);

  const frameData = {
    name: 'pages.about.commission',
    photo: require('../assets/images/frames/commission.png'),
    page: 'pages.about',
    preUrl: "/about",
    url: '/about/commission'
  }
  return (
    <>
      <section className="commission ">
        <AnimatedPage>
          <AnimatedPage>
            <div>
              <Container>

                <Frame {...frameData} />
                <Row className="commission-list py-5">
                  {structures.map((item, index) => (
                    <Col md="3"
                      key={index}
                    >
                      <Link to={`/about/commission/${item.id}`} className="commission d-flex align-items-center flex-wrap">
                        <div className="commission-name w-100">
                          {item?.name}
                        </div>
                        <div className="commission-more">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M14.4297 5.93L20.4997 12L14.4297 18.07" stroke="#202939" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M3.5 12H20.33" stroke="#202939" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                          </svg>
                        </div>
                      </Link>

                    </Col>
                  ))}
                </Row>
              </Container>
            </div>
          </AnimatedPage>
        </AnimatedPage>
      </section>
    </>
  );
};

export default Commission;
