import React, { useEffect } from "react";
import { Routes, Route, useLocation, Outlet } from "react-router-dom";
import Home from "./pages/home";
import About from "./pages/about";
import Login from "./pages/login";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./App.css";
import News from "./pages/news";
import NewsDetail from "./pages/news-detail";
import { AnimatePresence } from "framer-motion";
import { DataProvider } from "./services/data-context";
import Gallery from "./pages/gallery";
// import Judges from "./pages/judges";
// import JudgeDetail from "./pages/judge-detail";
import Constitution from "./pages/constitution";
import Contact from "./pages/contact";
import Error from "./pages/error";
import Useful from "./pages/useful";
import Search from "./pages/serach";
import Activities from "./pages/activities";
import ActivityDetail from "./pages/activity-detail";
import Commission from "./pages/commission";
import BoardDetail from "./pages/board-detail";
import Boards from "./pages/boards";
import { UserProvider } from "./services/user-context";
import Notifications from "./pages/notifications";

import "react-toastify/dist/ReactToastify.css";
import NotificationDetail from "./pages/notification-detail";
import CommissionDetail from "./pages/commission-detail";
import Partners from "./pages/partners";
import Links from "./pages/links";
import CommissionDetailList from "./pages/commission-detail-list";

export default function App() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
    document.querySelectorAll('.dropdown-toggle.show').forEach(dropdown => {
      dropdown.click();
    });
  }, [location]);

  return (
    <>
      <UserProvider>

        <Routes location={location}>

          <Route element={<ThemeLayout />}>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/search/:key" element={<Search />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/notifications/:id" element={<NotificationDetail />} />
            <Route path="/materials/:slug" element={<Useful />} />
            <Route path="/materials/links" element={<Links />} />
            <Route path="/about/commission" element={<Commission />} />
            <Route path="/about/commission/:id" element={<CommissionDetail />} />
            <Route path="/about/commission/:id/detail" element={<CommissionDetailList />} />
            <Route path="/about/boards" element={<Boards />} />
            <Route path="/about/partners" element={<Partners />} />
            <Route path="/about/boards/detail/:id" element={<BoardDetail />} />
            <Route path="/about/constitution" element={<Constitution />} />
            <Route path="/activity" element={<Activities />} />
            <Route path="/activity/:slug" element={<Activities />} />
            <Route path="/activity/detail/:id" element={<ActivityDetail />} />
            <Route path="/news" element={<News />} />
            <Route path="/gallery" element={<Gallery />} />
            {/* <Route path="/about/judges" element={<Judges />} />
            <Route path="/about/judges/detail/:id" element={<JudgeDetail />} /> */}
            <Route path="/news/detail/:id" element={<NewsDetail />} />
            {/* <Route path="*" element={<Navigate to="/" />} /> */}
            <Route path="*" element={<Error />} />
          </Route>

          <Route element={<WithoutLayout />}>
            <Route path="/login" element={<Login />} />
          </Route>
        </Routes>
      </UserProvider>
    </>
  );
}

const ThemeLayout = () => {
  return (
    <>
      <AnimatePresence mode="wait">
        <DataProvider>
          <Header />
          <Outlet />
          <Footer />
        </DataProvider>
      </AnimatePresence>
    </>
  );
};

const WithoutLayout = () => {
  return (
    <>
      <AnimatePresence mode="wait">
        <DataProvider>
          <Outlet />
        </DataProvider>
      </AnimatePresence>
    </>
  );
};
