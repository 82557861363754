import React, { useState } from "react";
import { Row } from "react-bootstrap";
import logo from "../assets/images/logo/logo-white.png";
import "../assets/scss/login.scss";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AnimatedPage from "../config/transition";
import LoginService from "../services/loginService";
import { useUser } from "../services/user-context";
import { toast } from "react-toastify";

const Login = () => {
  const navigate = useNavigate();
  const { login } = useUser();
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const { t } = useTranslation();

  const onChange = (e) => {
    const { name, value } = e.target;
    const newValue = value;

    setFormData({
      ...formData,
      [name]: newValue,
    });
  };
  const loginUser = async () => {
    try {
      if (formData.username && formData.password) {
        const response = await LoginService.login(formData);

        if (response?.statusCode === 200) {
          login(response.result);
          localStorage.setItem("user", JSON.stringify(response.result));
          navigate("/");
        } else {

          toast.error("login.error");
        }
      }else{
        toast.error("login.empty");
      }
    } catch (error) {
      toast.error("login.error");
    }
  };

  return (
    <>
      <AnimatedPage>

        <section id="login">
          <Row>
            <div className="col-md-5">
              <div className="bg-login d-flex align-items-end justify-content-center">
                <Link to="/" className="text-center">
                  <img src={logo} alt="..." />
                </Link>
              </div>
            </div>
            <div className="col-md-7 d-flex flex-wrap align-items-between ">
              <div className="gohome w-100 ">
                <div className="text-right">
                  <Link to="/" className="">
                    <i className="fa fa-arrow-left mr-3"></i>
                    <span>{t("tohome")}</span>
                  </Link>
                </div>
              </div>

              <div className="w-100 d-md-none">
                <Link to="/" className="text-center d-block my-4">
                  <img className="w-25" src={logo} alt="..." />
                </Link>
              </div>
              <div className="login pb-5">
                <h3 className="text-center mb-5 font-weight-bold">
                  {t("login")}
                </h3>
                {/* username */}
                <div className="form-group">
                  <label>
                    {t("username")} <span className="required">*</span>
                  </label>
                  <input
                    className="form-control"
                    name="username"
                    type="text"
                    onChange={onChange}
                    placeholder={t("username")}
                  ></input>
                </div>
                {/* pass */}
                <div className="form-group">
                  <label>
                    {t("password")} <span className="required">*</span>
                  </label>
                  <input
                    className="form-control"
                    name="password"
                    type="password"
                    onChange={onChange}
                    placeholder={t("password")}
                  ></input>
                </div>
                <div className="text-right">
                  <button onClick={() => loginUser()} className="btn mybtn">
                    {t("login")}
                  </button>
                </div>
              </div>
            </div>
          </Row>
        </section>
      </AnimatedPage>
    </>
  );
};

export default Login;
