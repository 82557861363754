import React from "react";
import { Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Error = () => {
    const {t} =  useTranslation();
  return (
    <>
      <section className="error">
        <Container>
          <Row>
            <div className="col-md-7">
                <h1 >404</h1>
                <p>{t("error.notfound")}</p>
            </div>
            <div className="col-md-5">
              <svg
                width="349"
                height="320"
                viewBox="0 0 349 320"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipath="url(#clip0_593_317)">
                  <path
                    d="M239.087 291.365H112.134C101.451 291.365 92.7997 299.992 92.7997 310.644V317.814H258.422V310.644C258.422 299.992 249.77 291.365 239.087 291.365Z"
                    stroke="#CC9966"
                    strokeWidth="3"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M216.64 272.085H134.596C123.913 272.085 115.262 280.712 115.262 291.365H235.974C235.974 280.712 227.308 272.085 216.64 272.085Z"
                    stroke="#CC9966"
                    strokeWidth="3"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M175.618 203.871L92.7997 62.604H258.422L175.618 203.871Z"
                    stroke="#CC9966"
                    strokeWidth="3"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M187.046 62.604H164.19V272.071H187.046V62.604Z"
                    stroke="#CC9966"
                    strokeWidth="3"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M292.531 77.0017C310.93 77.0017 325.603 92.8859 323.528 111.655C321.949 125.878 310.346 137.449 296.082 139.023C277.259 141.092 261.33 126.461 261.33 108.114V62.604H89.8915V106.978C89.8915 124.159 76.4173 138.935 59.2018 139.198C41.7526 139.475 27.5038 125.441 27.5038 108.1C27.5038 90.9186 41.4749 76.9872 58.7049 76.9872"
                    stroke="#CC9966"
                    strokeWidth="3"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M57.7989 115.342C61.9555 115.342 65.3251 111.982 65.3251 107.837C65.3251 103.693 61.9555 100.333 57.7989 100.333C53.6422 100.333 50.2726 103.693 50.2726 107.837C50.2726 111.982 53.6422 115.342 57.7989 115.342Z"
                    fill="#CC9966"
                    stroke="#CC9966"
                    strokeWidth="3"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M293.861 115.342C298.018 115.342 301.387 111.982 301.387 107.837C301.387 103.693 298.018 100.333 293.861 100.333C289.704 100.333 286.335 103.693 286.335 107.837C286.335 111.982 289.704 115.342 293.861 115.342Z"
                    fill="#CC9966"
                    stroke="#CC9966"
                    strokeWidth="3"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M238.035 49.343H120.61V62.6187H238.035V49.343Z"
                    stroke="#CC9966"
                    strokeWidth="3"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M214.082 36.0674H144.548V49.343H214.082V36.0674Z"
                    stroke="#CC9966"
                    strokeWidth="3"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M193.109 20.1841C195.182 12.5892 190.688 4.7567 183.071 2.68973C175.455 0.622758 167.6 5.10402 165.527 12.6989C163.454 20.2938 167.948 28.1263 175.565 30.1933C183.181 32.2602 191.036 27.779 193.109 20.1841Z"
                    stroke="#CC9966"
                    strokeWidth="3"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2.19214 244.31C23.6895 301.347 86.6033 301.347 108.101 244.31H2.19214Z"
                    fill="#CC9966"
                    stroke="#CC9966"
                    strokeWidth="3"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2.19214 244.31L55.1537 163.169L108.101 244.31"
                    stroke="#CC9966"
                    strokeWidth="3"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M55.1537 163.17V139.212"
                    stroke="#CC9966"
                    strokeWidth="3"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M240.899 244.31C262.397 301.347 325.311 301.347 346.808 244.31H240.899Z"
                    fill="#CC9966"
                    stroke="#CC9966"
                    strokeWidth="3"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M240.899 244.31L293.861 163.169L346.808 244.31"
                    stroke="#CC9966"
                    strokeWidth="3"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M293.861 163.17V139.212"
                    stroke="#CC9966"
                    strokeWidth="3"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_593_317">
                    <rect width="349" height="320" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Error;
