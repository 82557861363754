import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import AnimatedPage from "../config/transition";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import parse from "html-react-parser";
import "../assets/scss/home.scss";
import { useParams } from "react-router-dom";
import { Image } from 'primereact/image';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import ActivityService from "../services/activityService";

const ActivityDetail = () => {
  const params = useParams();

  const [activity, setActivity] = useState([]);

  useEffect(() => {
    const LoadActivity = async () => {
      try {
        const data = await ActivityService.getActivityDetail(params.id);
        setActivity(data.result);
      } catch (error) {
        console.error("API error Event", error);
      }
    };

    LoadActivity(params.id);
  }, [params]);

  return (
    <>
      <AnimatedPage>
        <Container>
          <section className="activity-slider py-5">
            <h3 className="label ">{activity?.header}</h3>
            <Swiper
              slidesPerView={3}
              pagination={{
                clickable: true,
              }}
              className="mySwiper"
              spaceBetween={15}
              breakpoints={{
                1200: {
                  slidesPerView: 1,
                },
              }}
              modules={[Pagination]}
            >
              <SwiperSlide>
                <div className="activity-photo">
                  <Image className="w-100" src={activity?.photo} alt="Image" preview />
                </div>
              </SwiperSlide>
              {activity?.photos?.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="activity-photo">
                    <Image className="w-100" src={item} alt="Image" preview />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>

            <div className="activity-content py-5">
              {parse(activity?.description ?? "")}
            </div>
          </section>
        </Container>
      </AnimatedPage>
    </>
  );
};

export default ActivityDetail;
