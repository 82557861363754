import React from "react";
import parse from "html-react-parser";
import '../assets/scss/about.scss'
import { Col } from "react-bootstrap";

const About = ({ text, photo, isSecound }) => {

  console.log(isSecound)
  return (
    <>
      <section className="about py-md-3">
        <div className="row mx-0">
          <Col md="7" className="d-flex align-items-center mb-3 ">
            <div className="px-md-5 pt-0">
              <div className="about-text p-0">{parse(text ?? '')}</div>
            </div>
          </Col>
          <Col md={{ order: isSecound ? 'first' : 'last' ,size:5}}  className={`pr-0 mb-3`}>
            <img
              className="about-img"
              src={photo?.photoName}
              alt="..."
            />
          </Col>

        </div>

      </section>
    </>
  );
};

export default About;
