import React, { useEffect, useState } from "react";
import "../assets/scss/activity.scss";
import AnimatedPage from "../config/transition";
import "../assets/scss/contact.scss";
import { useForm } from "react-hook-form";
import SettingsService from "../services/settingsService";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Frame from "../components/Frame";

const Contact = () => {
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
  } = useForm();
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    email: "",
    message: "",
    id: "",
  });

  const LoadData = async () => {
    const response = await SettingsService.getSettings();
    setData(response.result);
  };

  useEffect(() => {
    LoadData();
  }, []);

  const frameData = {
    name: 'pages.contact',
    photo: require('../assets/images/frames/contact.png'),
    page: 'pages.contact',
    url: '/contact'
  }


  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    setValue(name, value);
  };

  async function sendMessage(request) {
    if (isValid) {
      try {
        const response = await SettingsService.sendMessage(request);
        if (response?.statusCode === 200) {
          toast.success(response.message);
          reset();
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  }


  return (
    <>
      <AnimatedPage>
        <Container>
          <Frame {...frameData} />
          <section className="contact pb-5">


            <Row>
              <Col lg={{ span: 12, order: 1 }} sm={{ span: 12, order: 2 }} >
                <Row>
                  <Col md="6" className="mb-3">
                    <div className="br p-3">
                      <p className="font-weight-bold mb-3">
                        {t("apply")}
                      </p>
                      <form onSubmit={handleSubmit(sendMessage)}>
                        <Row>

                          <Col md="6">

                            <label htmlFor="name">
                              {t("name")}
                              {errors.name && (
                                <span className="ml-1 text text-danger">
                                  {errors.name.message}
                                </span>
                              )}
                            </label>

                            <div className="form-group">
                              <input
                                type="text"
                                name="name"
                                onChange={handleChange}
                                placeholder={t("name")}
                                className={`form-control ${errors.name && "is-invalid"
                                  }`}
                                {...register("name", {
                                  required: t('error.name.required'),
                                  minLength: {
                                    value: 3,
                                    message: t('error.name.minLength'),
                                  },
                                  pattern: {
                                    value: /^[a-zA-ZğüşıöçƏĞÜŞİÖÇ]+$/,
                                    message: t('error.name.valid'),
                                  },
                                })}
                              />
                            </div>
                          </Col>
                          {/* lastname */}
                          <Col md="6">
                            <label htmlFor="surname">
                              {t("surname")}
                              {errors.surname && (
                                <span className="ml-1 text text-danger">
                                  {errors.surname.message}
                                </span>
                              )}
                            </label>
                            <div className="form-group">
                              <input
                                type="text"
                                name="surname"
                                onChange={handleChange}
                                placeholder={t("surname")}
                                className={`form-control ${errors.surname && "is-invalid"
                                  }`}
                                {...register("surname", {
                                  required: t('error.surname.required'),
                                  minLength: {
                                    value: 3,
                                    message: t('error.surname.minLength'),
                                  },
                                  pattern: {
                                    value: /^[a-zA-ZğüşıöçƏĞÜŞİÖÇ]+$/,
                                    message: t('error.surname.valid'),
                                  },
                                })}
                              />
                            </div>


                          </Col>
                          {/* email */}
                          <Col md="12">
                            <label htmlFor="email">
                              {t("email")}
                              {errors.email && (
                                <span className="ml-1 text text-danger">
                                  {errors.email.message}
                                </span>
                              )}
                            </label>
                            <div className="form-group">
                              <input
                                type="text"
                                name="email"
                                onChange={handleChange}
                                placeholder={t("email")}
                                className={`form-control ${errors.email && "is-invalid"
                                  }`}
                                {...register("email", {
                                  required: t("error.email.required"),
                                  minLength: {
                                    value: 10,
                                    message: t("error.email.length"),
                                  },
                                  pattern: {
                                    value: /^[\w.%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
                                    message: t("error.email.format"),
                                  },
                                })}
                              />

                            </div>

                          </Col>
                          {/* desc */}
                          <Col md="12">
                            <label htmlFor="message">
                              {t("message")}
                              {errors.message && (
                                <span className="ml-1 text text-danger">
                                  {errors.message.message}
                                </span>
                              )}
                            </label>
                            <div className="form-group">
                              <textarea name="message" onChange={handleChange}
                                className={`form-control ${errors.message && "is-invalid"
                                  }`}
                                {...register("message", {
                                  required: t("error.description"),
                                  minLength: {
                                    value: 10,
                                    message: t("error.descripiton2"),
                                  },

                                })} placeholder={t("description")} id="" cols="20" rows="5">

                              </textarea>
                            </div>

                          </Col>
                          <Col md="12">
                            <div className="text-center">
                              <button type="submit" className="btn btn-primary px-5 w-100">{t("send")}</button>
                            </div>
                          </Col>
                        </Row>
                      </form>
                    </div>

                  </Col>
                  <Col md="6" className="mb-3">
                    <iframe className="br" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3039.526128794179!2d49.84226166109923!3d40.37503051001558!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40307daf75cc2471%3A0xe0267dac4b2e7a8e!2zMTggQsO8bGLDvGwgUHJvc3Bla3RpLCBCYWvEsQ!5e0!3m2!1saz!2saz!4v1725434901595!5m2!1saz!2saz" width="100%" height="100%" title="1" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                  </Col>
                </Row>
              </Col>

              <Col lg={{ span: 12, order: 2 }} sm={{ span: 12, order: 1 }} className="mt-md-5 mb-3">
                <div className="infos">

                  <Row >
                    <Col md="4" >
                      <div className=" inner d-flex align-items-center">
                        <div className="icon">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M15.6314 14.4L13.1114 16.9C10.6114 15.47 8.54136 13.4 7.11136 10.9L9.61136 8.38C9.84136 8.14 9.94136 7.81 9.88136 7.48L9.13136 3.8C9.04136 3.34 8.63136 3 8.15136 3H4.00136C3.44136 3 2.97136 3.47 3.00136 4.03C3.17136 6.92 4.05136 9.63 5.43136 12C7.01136 14.73 9.28136 16.99 12.0014 18.57C14.3714 19.94 17.0814 20.83 19.9714 21C20.5314 21.03 21.0014 20.56 21.0014 20V15.85C21.0014 15.37 20.6614 14.96 20.2014 14.87L16.5314 14.14C16.3716 14.1052 16.2056 14.1108 16.0485 14.1562C15.8913 14.2015 15.748 14.2853 15.6314 14.4Z" fill="#4C84DB" />
                          </svg>
                        </div>
                        <div className="info ml-3">
                          <label className="d-block">{t("our.phone")}</label>

                          <a href={`tel:${data.phone1}`}>{data.phone1}</a>

                          {data.phone2 && (
                            <>
                              <span className="px-3">|</span>
                              <a href={`tel:${data.phone2}`}>{data.phone2}</a>
                            </>
                          )}

                        </div>
                      </div>
                    </Col>
                    <Col md="5" >
                      <div className=" inner brr d-flex align-items-center">
                        <div className="icon">
                          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                            <path d="M21.13 15.6975C21.8385 14.367 22.208 12.8824 22.2057 11.375C22.2057 6.28931 18.0831 2.16669 12.9974 2.16669C7.9117 2.16669 3.78908 6.28931 3.78908 11.375C3.78535 13.5473 4.5532 15.6502 5.95574 17.309L5.96658 17.3225C5.97037 17.3258 5.97362 17.3301 5.97633 17.3334H5.95574L11.4201 23.1346C11.6226 23.3496 11.867 23.521 12.1382 23.6381C12.4094 23.7552 12.7017 23.8156 12.9971 23.8156C13.2925 23.8156 13.5848 23.7552 13.856 23.6381C14.1272 23.521 14.3716 23.3496 14.5742 23.1346L20.0391 17.3334H20.0185L20.0272 17.3231L20.0282 17.322C20.0672 17.2754 20.1062 17.2288 20.1447 17.1811C20.5204 16.7194 20.8506 16.2223 21.1305 15.697L21.13 15.6975ZM12.9996 14.8959C12.1376 14.8959 11.311 14.5534 10.7015 13.944C10.092 13.3345 9.74958 12.5078 9.74958 11.6459C9.74958 10.7839 10.092 9.95725 10.7015 9.34776C11.311 8.73826 12.1376 8.39585 12.9996 8.39585C13.8615 8.39585 14.6882 8.73826 15.2977 9.34776C15.9072 9.95725 16.2496 10.7839 16.2496 11.6459C16.2496 12.5078 15.9072 13.3345 15.2977 13.944C14.6882 14.5534 13.8615 14.8959 12.9996 14.8959Z" fill="#4C84DB" />
                          </svg>
                        </div>
                        <div className="info ml-3">
                          <label className="d-block">{t("our.address")}</label>
                          <a >
                            {data.address}
                          </a>

                        </div>
                      </div>
                    </Col>
                    <Col md="3">
                      <div className=" inner d-flex align-items-center">
                        <div className="icon ">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M22.7987 17.4V6.59999C22.7987 5.60399 21.9947 4.79999 20.9987 4.79999H4.18672C3.19072 4.79999 2.38672 5.60399 2.38672 6.59999V17.4C2.38672 18.396 3.19072 19.2 4.18672 19.2H20.9987C21.9947 19.2 22.7987 18.396 22.7987 17.4ZM21.2267 6.46799C21.6227 6.86399 21.4067 7.27199 21.1907 7.47599L16.3187 11.94L20.9987 16.812C21.1427 16.98 21.2387 17.244 21.0707 17.424C20.9147 17.616 20.5547 17.604 20.3987 17.484L15.1547 13.008L12.5867 15.348L10.0307 13.008L4.78672 17.484C4.63072 17.604 4.27072 17.616 4.11472 17.424C3.94672 17.244 4.04272 16.98 4.18672 16.812L8.86672 11.94L3.99472 7.47599C3.77872 7.27199 3.56272 6.86399 3.95872 6.46799C4.35472 6.07199 4.76272 6.26399 5.09872 6.55199L12.5867 12.6L20.0867 6.55199C20.4227 6.26399 20.8307 6.07199 21.2267 6.46799Z" fill="#4C84DB" />
                          </svg>
                        </div>
                        <div className="info ml-3">
                          <label className="d-block">{t("our.email")}</label>
                          <a href={`mailto:${data.email}`}>{data.email}</a>
                        </div>

                      </div>
                    </Col>
                  </Row>
                </div>

              </Col>

            </Row>


          </section>
        </Container>
      </AnimatedPage>
    </>
  );
};

export default Contact;
