// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const lang = localStorage.getItem('language') || 'az';

const resources = {};

i18n
  .use(initReactI18next)
  .init({
    fallbackLng: lang, 
    debug: false,
    resources: resources, 
  });

export default i18n;