// src/services/newsService.js

import { get } from "./api-service";

const AboutService = {
  getAboutData: async (param) => {
    try {
      const response = await get(`commonTexts/${param}`).catch((error) =>
        console.error(error)
      );
      if (!response.statusCode === 200) {
        throw new Error("API error");
      }
      const data = await response;
      return data;
    } catch (error) {
      throw error;
    }
  },
  getAboutDatas: async () => {
    try {
      const response = await get(`commonTexts/getall`).catch((error) =>
        console.error(error)
      );
      if (!response.statusCode === 200) {
        throw new Error("API error");
      }
      const data = await response;
      return data;
    } catch (error) {
      throw error;
    }
  },
};

export default AboutService;
