import React, { useEffect, useState } from "react";
import AboutService from "../services/aboutService";
import AboutComponent from "../components/About";
import AnimatedPage from "../config/transition";
import Frame from "../components/Frame";
import { Container } from "react-bootstrap";
import { t } from "i18next";
const About = () => {

  const [about, setAbout] = useState([]);
  const [about2, setAbout2] = useState([]);

  const loadAboutData = async () => {
    try {
      const data = await AboutService.getAboutData("about");
      setAbout(data.result);
    } catch (error) {
      console.error("API error About", error);
    }
  };
  
  const loadAboutData2 = async () => {
    try {
      const data = await AboutService.getAboutData("about2");
      data.result.isSecound = true
      setAbout2(data.result)

    } catch (error) {
      console.error("API error About", error);
    }
  };

  useEffect(() => {
    loadAboutData();
    loadAboutData2();
  }, []);


  const frameData = {
    name:'about',
    photo: require('../assets/images/frames/about.png'),
    page:'pages.about',
    preUrl:"/about",
    url:'/about'
  }

  return (
    <>
      <section className="mb-5">
        <AnimatedPage>
          <Container>
            <Frame {...frameData} />
            <AboutComponent {...about} />
            <AboutComponent {...about2} />
            <h5 className="about-bottom-text">
              {t('page.about.text')}
            </h5>
          </Container>
        </AnimatedPage>
      </section >
    </>
  );
};

export default About;
