// apiService.js

import axios from "axios";
import { toast } from "react-toastify";

 

const BASE_URL = process.env.REACT_APP_API_URL;
const token = JSON.parse(localStorage.getItem("user") ?? "[]")?.token;
const lang = localStorage.getItem("language") || "az";

const commonHeaders = {
  Accept: "*/*",
  "Content-Type": "application/json; charset=utf-8",
  "Accept-Language": lang,
};

if (token) {
  commonHeaders.Authorization = "Bearer " + token;
}

async function handleResponse(response) {
  if (response?.status === 401) {
    logout();
  }

  return response?.data;
}

async function logout() {
  toast.warning("Sessiyanız bitmişdir");
  localStorage.clear();
  window.location.href = "/login";
}



export async function get(endpoint) {
  try {
    const response = await axios.get(`${BASE_URL}/${endpoint}`, {
      headers: commonHeaders,
    });

    return handleResponse(response);
  } catch (error) {
    if (error.response?.status === 401) {
      logout();
    }
    throw error;
  }
}

export async function post(endpoint, data) {
  const isFormData = data instanceof FormData;

  const customHeaders = {
    "Content-Type": isFormData ? "multipart/form-data" : "application/json",
  };
  const headers = {
    ...commonHeaders,
    ...customHeaders,
  };

  try {
    const response = await axios.post(
      `${BASE_URL}/${endpoint}`,
      isFormData ? data : JSON.stringify(data),
      {
        headers: headers,
      }
    );

    return handleResponse(response);
  } catch (error) {
    if (error.response?.status === 401) {
      logout();
    }
    throw error;
  }
}

// put
export async function put(endpoint, data) {
  const isFormData = data instanceof FormData;

  const customHeaders = {
    "Content-Type": isFormData ? "multipart/form-data" : "application/json",
  };
  const headers = {
    ...commonHeaders,
    ...customHeaders,
  };

  try {
    const response = await axios.put(
      `${BASE_URL}/${endpoint}`,
      isFormData ? data : JSON.stringify(data),
      {
        headers: headers,
      }
    );
    return handleResponse(response);
  } catch (error) {
    if (error.response?.status === 401) {
      logout();
    }
    throw error;
  }
}

// delete
export async function del(endpoint) {
  try {
    const response = await axios.delete(`${BASE_URL}/${endpoint}`, {
      headers: commonHeaders,
    });
    return handleResponse(response);
  } catch (error) {
    if (error.response?.status === 401) {
      logout();
    }
    throw error;
  }
}