import React, { useEffect, useState } from "react";
import AnimatedPage from "../config/transition";
import "swiper/css";
import "swiper/css/navigation";

import "../assets/scss/notifications.scss";
import "../assets/scss/home.scss";
import NotificationsService from "../services/notificationService";
import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import parse from "html-react-parser";

const NotificationDetail = () => {
  const [notification, setNotification] = useState([]);
  const params = useParams();


  useEffect(() => {
    const loadNotificationDetail = async () => {
      try {
        const response = await NotificationsService.getNotificationDetail(
          params.id
        );
        setNotification(response.result);
      } catch (error) {
        console.error("API error news", error);
      }
    };

    loadNotificationDetail(params.id);
  }, [params]);

  return (
    <>
      <AnimatedPage>
        <section className="notification-detail py-5">
          <Container>
            <h3>{notification.header}</h3>
            <div className="notification-slider">
              <div className="notification-photo">
                <img alt="..." src={notification?.photo?.photoName}></img>
              </div>

              <div className="notification-content py-5">
                {parse(notification?.description ?? "")}
              </div>
            </div>
          </Container>
        </section>
      </AnimatedPage>
    </>
  );
};

export default NotificationDetail;
